import React, { useState,useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet,ScrollView } from 'react-native';
import { Divider } from "react-native-paper"
import AsyncStorage from '@react-native-async-storage/async-storage';
import Apis from './Apis';
import { Root, Popup } from 'react-native-popup-confirm-toast'
import { Ionicons } from "@expo/vector-icons";

function Account({navigation}) {

  const [userName, setuserName] = useState("")
  const [mobile, setMobile] = useState("")
  const [logo, setLogo] = useState("")
  const [storeName, setStoreName] = useState("")
  const [category, setCategory] = useState("")
  const [address, setAddress] = useState("")
  const [special, setSpecial] = useState(0)
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async() => {
      const userData=await Apis.getStoreData()
      if(userData.status=="success"){
        //console.log(userData.data)
        const {name}=userData.data[0]
        setuserName(name)
      
      }
  
    });

    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  return (
    <View style={styles.container}>
      <ScrollView
      showsVerticalScrollIndicator={false}
      >
<View
style={{
  flexDirection:"row-reverse"
}}
>
<View
style={{
  marginTop:"auto",
  marginBottom:"auto"
}}
>
<Text style={[styles.titleText,{textAlign:"right"}]}>مرحباً, {userName}</Text>
</View>


</View>
<Divider/>
<View

>
<TouchableOpacity style={styles.settingContainer} onPress={() => {navigation.push("changepassword")}}>
        <Text style={styles.settingText}>تغيير كلمة المرور</Text>
        <Image source={require('./assets/left-arrow.png')} style={styles.arrow} />

      </TouchableOpacity>
      <Divider/>
    
     

      
</View>


     
      </ScrollView>
      <TouchableOpacity style={styles.settingContainer} onPress={async() => {  

//const response= await Apis.logout()
await AsyncStorage.setItem("token","")

//alert(JSON.stringify(response))
 navigation.reset({
        index: 0,
        routes: [{ name: 'landing' }],
      }); 
      }}>
<Text style={[styles.settingText,{color:"#E63946dd",marginLeft:"auto",marginRight:"auto"}]}>تسجيل الخروج</Text>
</TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
    paddingTop:70
  },
  settingContainer: {
    flexDirection: 'row-reverse',
    justifyContent:"space-between",
    alignItems: 'center',
    paddingVertical: 20,
  },
  settingText: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Cairo_400Regular",

  },
  titleText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: "Cairo_700Bold",

    marginBottom:200
  },
  arrow:{
    width:15,
    height:15
  }
});

export default Account;
