
import React from "react"
import { ScrollView } from "react-native";
import { View,StyleSheet,Image,Text,TouchableOpacity } from "react-native"
import { Divider } from "react-native-paper"
import { Badge } from "react-native-ui-lib";
import OrderCompleteCartSlider from "./OrderCompleteCartSlider";
import { Popup } from 'react-native-popup-confirm-toast'
import Toast from "react-native-toast-message";
 const PreviousOrder=({navigation,route})=>{
 
  
    const {products,status,estimatedShippingDate,orderData} = route.params
    return(
        <>

          <View style={styles.container}>
            
{
    <>
    
        <View
        style={styles.Innercontainer1}
        >
            <Text style={styles.settingText}>رقم الطلبية : {orderData.orderId}</Text>
            <Text style={styles.settingText}>تاريخ الطلبية : {orderData.orderDate}</Text>
        <Text style={styles.settingText}>المجموع : {orderData.subtotal} ₪</Text>
        <Text style={styles.settingText}>الشحن : {orderData.shipping} ₪</Text>
        <Text style={styles.settingText}>المجموع الكلي : {orderData.total} ₪</Text>
        <Text style={styles.settingText}>الكوبون : {orderData.coupon}</Text>
        <Text style={styles.settingText}>عنوان الشحن : {orderData.address}</Text>
        <Text style={styles.settingText}>الاسم : {orderData.name}</Text>
        <Text style={styles.settingText}>رقم الهاتف : {orderData.mobile}</Text>
        </View>
        
    </>
      
      
  
}
         <ScrollView
showsVerticalScrollIndicator={false}
>
   
           <View style={styles.Innercontainer}>


   
       


      
        <Divider style={{marginTop:20}}/>

        <OrderCompleteCartSlider products={products} navigation={navigation} onchange={(type,msg)=>{
                 type=="success"?(
                  Toast.show({
                    type: 'success',
                    text1:msg
                  }) ):(
                    Toast.show({
                      type: 'error',
                      text1:msg
                    }) )
           
              }}/>



        </View> 
             </ScrollView>
        </View>
  

        </>
    )
}

const styles = StyleSheet.create({
    container: {
      backgroundColor: "white",
      width: "100%",
      height: "100%",
    },
    Innercontainer: {
        backgroundColor: "white",
        width: "90%",
        height: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      },
      Innercontainer1: {
        backgroundColor: "white",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    searchInput: {
      width: "100%",
      backgroundColor: "#0000000d",
      marginLeft: "auto",
      marginRight: "auto",
      padding: 13,
      borderRadius: 10,
    },
    searchInputText: {
      textAlign: "right",
      color: "#00000080",
      fontFamily: "Cairo_400Regular",
    },
    sectionTitle:{
        fontFamily: "Cairo_700Bold",
        fontSize:18,
        marginBottom:20
      },
      option:{
        textAlign:"right",
        paddingHorizontal:20,
        paddingVertical:15,
        fontFamily: "Cairo_700Bold",
        fontSize:14
    },
    buynowContainer:{
        backgroundColor:"#E63946dd",
        height:60,
        borderRadius:10,
        justifyContent:"center",
        alignItems:"center"
      },
      checkoutbuttoncontainer:{
        width:"100%",
        backgroundColor:"#E6394633",
        height:60,
        borderRadius:10,
        justifyContent:"center",
        alignItems:"center",
        marginHorizontal:10
      },
      checkcouponbutton:{
        fontFamily: "Cairo_700Bold",
        color:"white",
       textAlign:"center"
        },
        settingText: {
            fontSize: 13,
            marginLeft: 10,
            fontFamily: "Cairo_400Regular", 
            textAlign:"right"
        
          }
  });
  
export default PreviousOrder