import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View, TouchableOpacity, Image } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  getStateFromPath,
  NavigationContainer,
  StackActions,
} from "@react-navigation/native";
import {
  useFonts,
  Cairo_200ExtraLight,
  Cairo_300Light,
  Cairo_400Regular,
  Cairo_500Medium,
  Cairo_600SemiBold,
  Cairo_700Bold,
  Cairo_800ExtraBold,
  Cairo_900Black,
} from "@expo-google-fonts/cairo";
import AddProduct from "./AddProduct";
import Toast, { BaseToast, ErrorToast } from "react-native-toast-message";
import Landing from "./Landing";
import SignUp from "./SignUp";
import NewPassword from "./NewPassword";
import OTP from "./OTP";
import ForgotPassword from "./ForgotPassword";
//import { ApplicationProvider, Layout } from "@ui-kitten/components";
import * as eva from "@eva-design/eva";
import TabNavigation from "./TabNavigation";
import EditProduct from "./EditProduct";
import { Root, Popup } from 'react-native-popup-confirm-toast'
import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import StoreInfo from "./StoreInfo";
import ChangePassword from "./ChangePassword";
import PreviousOrder from './PreviousOrder';
import SubCategoriesTable from "./Subcategories";
const Stack = createNativeStackNavigator();

export default function App() {


  useEffect(() => {
  
    
  }, [])
  let [fontsLoaded] = useFonts({
    Cairo_200ExtraLight,
    Cairo_300Light,
    Cairo_400Regular,
    Cairo_500Medium,
    Cairo_600SemiBold,
    Cairo_700Bold,
    Cairo_800ExtraBold,
    Cairo_900Black,
  });
  const Header = ({ title, navigation }) => {
    return (
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Image
            source={require("./assets/left-arrow.png")}
            style={styles.arrow}
          />
        </TouchableOpacity>
        <Text style={styles.title}>{title}</Text>
      </View>
    );
  };

  const toastConfig = {
    /*
      Overwrite 'success' type,
      by modifying the existing `BaseToast` component
    */
    success: (props) => (
      <BaseToast
        {...props}
        style={{ borderLeftColor: "#32BEA6", height: "100%" }}
        text1NumberOfLines={10}
        contentContainerStyle={{ paddingHorizontal: 15, paddingVertical: 15 }}
        text1Style={{
          fontSize: 15,
          textAlign: "right",
          fontFamily: "Cairo_400Regular",
          fontWeight: "normal",
        }}
      />
    ),
    /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
    error: (props) => (
      <ErrorToast
        {...props}
        style={{ borderLeftColor: "#E63946dd", height: "100%" }}
        contentContainerStyle={{ paddingHorizontal: 15, paddingVertical: 15 }}
        text1NumberOfLines={10}
        text1Style={{
          fontSize: 15,
          textAlign: "right",
          fontFamily: "Cairo_400Regular",
          fontWeight: "normal",
        }}
        text2Style={{
          fontSize: 15,
        }}
      />
    ),
  };
  return (
    <>
      {fontsLoaded && (
        <>
          <StatusBar backgroundColor="#000" style="dark-content" />
        {  /*<Root>
  
      </Root>*/}
          <View style={{ zIndex: 999, elevation: 999,width:"100%",position:"fixed",right:0 }}>
            <Toast config={toastConfig} style={{
              left:200
            }}/>
          </View>
          <NavigationContainer>
              <Stack.Navigator>
                <Stack.Screen
                  name="landing"
                  component={Landing}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="Home"
                  component={TabNavigation}
                  options={{
                    headerShown: false,
                  }}
                />

<Stack.Screen
                  name="addProduct"
                  component={AddProduct}
                  options={{
                    header: ({ navigation, route, options }) => {
                      return (
                        <Header navigation={navigation} title={"إضافة منتج"} />
                      );
                    },
                  }}
                />
                   <Stack.Screen
                  name="subcategories"
                  component={SubCategoriesTable}
                  options={{
                    header: ({ navigation, route, options }) => {
                      return (
                        <Header navigation={navigation} title={"التصنيفات الفرعية"} />
                      );
                    },
                  }}
                />
                <Stack.Screen
                  name="editProduct"
                  component={EditProduct}
                  options={{
                    header: ({ navigation, route, options }) => {
                      return (
                        <Header navigation={navigation} title={"تعديل منتج"} />
                      );
                    },
                  }}
                />
                <Stack.Screen
                  name="signup"
                  component={SignUp}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="newpassword"
                  component={NewPassword}
                  options={{
                    headerShown: false,
                  }}
                />
                <Stack.Screen
                  name="otp"
                  component={OTP}
                  options={{
                    headerShown: false,
                  }}
                />
                        <Stack.Screen name="previousorder" component={PreviousOrder}     options={{
                  header: ({ navigation, route, options }) => {
                    return (
                      <Header navigation={navigation} title={"طلبية"}/>
                    );
                  },
                }}/>
             
                <Stack.Screen
                  name="forgotpassword"
                  component={ForgotPassword}
                  options={{
                    headerShown: false,
                  }}
                />
                  <Stack.Screen
                  name="storeInfo"
                  component={StoreInfo}
                  options={{
                    header: ({ navigation, route, options }) => {
                      return (
                        <Header navigation={navigation} title={"معلومات المتجر"} />
                      );
                    },
                  }}
                />
                        <Stack.Screen
                  name="changepassword"
                  component={ChangePassword}
                  options={{
                    header: ({ navigation, route, options }) => {
                      return (
                        <Header navigation={navigation} title={"تغيير كلمة المرور"} />
                      );
                    },
                  }}
                />
              </Stack.Navigator>
            </NavigationContainer>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  header: {
    paddingTop: 40,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start", // align items to start of container
  },
  arrow: {
    width: 20,
    height: 20,
    marginLeft: 10,
    bottom: 0,
    position: "absolute",
    padding: 5,
  },
  title: {
    fontSize: 20,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 15,
    fontFamily: "Cairo_800ExtraBold",
  },
  Modal: {
    backgroundColor: "white",
    alignItems: "center",
    padding: 20,
    borderRadius: 20,
  },
  ModalText: {
    fontFamily: "Cairo_700Bold",
    marginVertical: 20,
  },
});
