
import React,{useState} from "react"
import {Text, View ,StyleSheet,ActivityIndicator,TouchableOpacity } from "react-native"
import { TextInput } from 'react-native-paper';
import { ActionSheet,Checkbox } from "react-native-ui-lib"; 
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Toast from 'react-native-toast-message'
import Apis from "./Apis";
import AsyncStorage from "@react-native-async-storage/async-storage";
 const ChangePassword=({navigation})=>{

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading,setLoading] = useState(false)
    
 
    async function editPassword(){
      setLoading(true)
      if(currentPassword&&newPassword&&confirmPassword){
        if(confirmPassword!==newPassword){
          Toast.show({
            type: 'error',
            text1:"كلمة المرور لا تطابق التأكيد"
          })
          setLoading(false)
  
        }
        else{
          const response=await Apis.UpdatePasswordStore(currentPassword,newPassword);
          setLoading(false)
          if(response.status=="success"){
            Toast.show({
              type: 'success',
              text1:"تم تعديل كلمة المرور بنجاح"
            })
            await AsyncStorage.setItem("token","")
            navigation.reset({
              index: 0,
              routes: [{ name: 'landing' }],
            });
              
          }
          else{
            Toast.show({
              type: 'error',
              text1:response.msg
            })
          }
        }
      
      }
      else{
        Toast.show({
          type: 'error',
          text1:"جميع الحقول اجبارية"
        })
        setLoading(false)

      }
  
    }
    return(
        <>
         <View style={{backgroundColor:"white",paddingBottom:20,paddingTop:10,borderTopWidth:0.5,borderColor:"#00000044",position:"absolute",bottom:0,zIndex:10,width:"100%",paddingHorizontal:15}}>
        <TouchableOpacity style={{width:"100%",height:"100%",justifyContent:"center"}}
        disabled={loading}
        onPress={()=>{
          editPassword()
          
        }}
        >

        <View style={styles.buynowContainer}>
        {
  loading?
  <ActivityIndicator size="small" color="#fff" />
  :    <Text style={styles.checkcouponbutton}>

  حفظ    
        </Text>
}
</View>
</TouchableOpacity>


          

        </View>  
      <View style={styles.container}>
     
      <KeyboardAwareScrollView
            keyboardShouldPersistTaps='always'
            enableOnAndroid={true}
        >
            <View style={styles.Innercontainer}>

 

            <TextInput
      label="كلمة المرور الحالية"
      placeholder="كلمة المرور الحالية"
      onChangeText={text => setCurrentPassword(text)}
      secureTextEntry
  value={currentPassword}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right"}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  />
     <TextInput
      label="كلمة المرور الجديدة"
      placeholder="كلمة المرور الجديدة"
      onChangeText={text => setNewPassword(text)}
      secureTextEntry
  value={newPassword}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right"}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  />
      <TextInput
      label="تأكيد كلمة المرور"
      placeholder="تأكيد كلمة المرور"
      onChangeText={text => setConfirmPassword(text)}
      secureTextEntry
  value={confirmPassword}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right",marginBottom:20}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  />
    </View>
    </KeyboardAwareScrollView>

</View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
      backgroundColor: "white",
      width: "100%",
      height: "100%",
    },
    Innercontainer: {
      backgroundColor: "white",
      width: "90%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop:50
    },
    searchInput: {
      width: "100%",
      backgroundColor: "#0000000d",
      marginLeft: "auto",
      marginRight: "auto",
      padding: 13,
      borderRadius: 10,
    },
    searchInputText: {
      textAlign: "right",
      color: "#00000080",
      fontFamily: "Cairo_400Regular",
    },
    sectionTitle:{
        fontFamily: "Cairo_700Bold",
        fontSize:18,
        marginBottom:20
      },
      option:{
        textAlign:"right",
        paddingHorizontal:20,
        paddingVertical:15,
        fontFamily: "Cairo_700Bold",
        fontSize:14
    },
    buynowContainer:{
        backgroundColor:"#E63946dd",
        height:60,
        borderRadius:10,
        justifyContent:"center",
        alignItems:"center"
      },
      checkoutbuttoncontainer:{
        width:"100%",
        backgroundColor:"#E6394633",
        height:60,
        borderRadius:10,
        justifyContent:"center",
        alignItems:"center",
        marginHorizontal:10
      },
      checkcouponbutton:{
        fontFamily: "Cairo_700Bold",
        color:"white",
       textAlign:"center"
        },
  });
  
export default ChangePassword