import React,{useEffect, useState} from 'react';
import { View, Text, Image, Animated,StyleSheet,TouchableOpacity } from 'react-native';
import { Ionicons } from "@expo/vector-icons";
import { ProductActions } from './ProductActions';
import { OverflowMenuWithDisabledItemsShowcase } from './OrderActions';

function OrderCompleteCartItem(props) {
  const { product,navigation,onchange } = props;

  const [quantity, setQuantity] = useState(product.quantity)
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [actionsModalVisible,setActionsModalVisible]=useState(false)
  const [detailHeight] = useState(new Animated.Value(0));
  const animateDetail = (visibility) => {
    Animated.timing(detailHeight, {
      toValue: visibility ? 100 : 0, // 200 is an example value, use the appropriate height for your detail component
      duration: 500,
    }).start();
    setIsDetailVisible(visibility)
  };

  return (
    <> 

      <View style={styles.container}>
      {
      actionsModalVisible&& <ProductActions special={product.special==1?true:false} id={product.productId} notactivated={product.rejected==1?false:product.underreview==1?false:product.activated==0?true:false} setVisible={setActionsModalVisible} navigation={navigation}/>
    }
      
      <TouchableOpacity
      style={{marginTop:"auto",marginBottom:"auto"}}
      onPress={()=>{
        //navigation.push("ProductPage")
        animateDetail(!isDetailVisible)

      }}
      >
        <View style={{backgroundColor:"#E63946dd",padding:2,borderRadius:10}}>
        {isDetailVisible?<Ionicons name="arrow-up-outline" color={"white"} size={25} />:<Ionicons name="arrow-down-outline" color={"white"} size={25} />}

        </View>
      

        </TouchableOpacity>
       
        <OverflowMenuWithDisabledItemsShowcase product={product} onchange={onchange}/>


     
      <View style={styles.textContainer}>
      <Text style={styles.title} numberOfLines={1}>{product.productId}#</Text>
      <Text style={styles.title} numberOfLines={1}>{product.title}</Text>
      <Text style={styles.subtext}>{product.price} X {product.quantity} </Text>
      </View>
      <TouchableOpacity
      onPress={()=>{
       
        setActionsModalVisible(true)

      }}
      >  
          <Image style={styles.image} source={product.image} />

        </TouchableOpacity>
    </View>
  
    <Animated.View style={{ maxHeight: detailHeight.interpolate({
          inputRange: [0, 100],
          outputRange: ['0%', '100%'],
        })

        ,paddingHorizontal:10
         }}>

{
      isDetailVisible&&(<>
       <View
    
       >
      {
    
       product.productDetails.map(detail=>{
          if(detail.type=="color"){
            return(<>
                 {isDetailVisible&&(
        
        
        <View style={{flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}>

     
    <View style={{width:14,height:14,backgroundColor:detail.value,borderRadius:7,marginTop:"auto",marginBottom:"auto",marginRight:10}}>
    
    </View>
    
    
        
    <Text style={styles.detailsData}>
                   {detail.name}:
                </Text>
        </View>
          )
    
    }
            </>
          
            )
          }
          else{

            return(
              <>
                {isDetailVisible&&(

                                <Text style={styles.detailsData}>{detail.name}: {detail.value}</Text>

                )}
              </>
            )
          }
        })


    }
       
        </View>
      </>)
    }
       
      </Animated.View>
    </>
 
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding:10
  },
  textContainer: {
    flex: 1,
    paddingHorizontal:10
  },
  quantityContainer: {
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    
    height: 100,
    aspectRatio:2/3,
    borderRadius:10
  },
  title: {
    fontSize: 14,
    textAlign:"right",
    fontFamily: "Cairo_700Bold",


  },
  subtext: {
    fontSize: 14,
    color: 'gray',
    textAlign:"right",
    fontFamily: "Cairo_400Regular",


  },
  price: {
    fontSize: 14,
    color: '#E63946dd',
    textAlign:"right",
    fontFamily: "Cairo_400Regular",


  },
  delete:{
    fontSize: 14,
    textAlign:"right",
    fontFamily: "Cairo_400Regular",
    color:"#457B9D"

  },
  ConformModal:{
    backgroundColor:"white",
    alignItems:"center",
    padding:20,
    borderRadius:20
  },
  deleteButton:{
    backgroundColor:"#E63946dd",
    padding:10,
    borderRadius:10,
    paddingHorizontal:30,
    marginHorizontal:10
  },
  cancelbutton:{
    backgroundColor:"#1D3557",
    padding:10,
    borderRadius:10,
    paddingHorizontal:30,
    marginHorizontal:10

  },
  deleteText:{
    color:"white",
    fontFamily: "Cairo_700Bold",

  },
  cancelText:{
    color:"white",
    fontFamily: "Cairo_700Bold",


  },
  confirmtext:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20
  },

  detailsData:{
    textAlign:"right",
    fontFamily: "Cairo_400Regular",

  }
});

export default OrderCompleteCartItem;
