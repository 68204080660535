import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet,TextInput,TouchableWithoutFeedback, Keyboard,ActivityIndicator  } from 'react-native';
import { Divider } from "react-native-paper"
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Apis from './Apis';

import { Dialog ,PanningProvider} from 'react-native-ui-lib';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ErrorModal } from './ErrorModal';
function ForgotPassword({navigation}) {

    const [loginMobile,setLoginMobile]=useState("")
    const [ActivityIndicatorVisible,setActivityIndicatorVisible]=useState(false)
    const [errorModalVisible,setErrorModalVisible]=useState(false)
    const [errorModalMessage,setErrorModalMessage]=useState("")
    
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>

    <View style={styles.container}>
    {
      errorModalVisible&& <ErrorModal msg={errorModalMessage} setVisible={setErrorModalVisible}/>
    }
   
     <View style={{marginTop:"auto",marginBottom:"auto"}}>
   
     <Text style={styles.header}>
      نسيت كلمة المرور
    </Text>

    <TextInput
      label="رقم الهاتف"
      placeholder="رقم الهاتف"
      onChangeText={text => setLoginMobile(text)}

  value={loginMobile}
  style={styles.input}

  />
  <TouchableOpacity
  style={styles.login}
  disabled={ActivityIndicatorVisible}

  onPress={async ()=>{
    setActivityIndicatorVisible(true)

    if(!ActivityIndicatorVisible){
      const response=await Apis.forgotpassword(loginMobile)
      if(response.status=="success"){
  
        navigation.replace("otp",{
          mobile:loginMobile,
          target:"forgotpassword"
        })
      }
      else{
        setActivityIndicatorVisible(false)
        setErrorModalMessage(response.msg)
        setErrorModalVisible(true)
      }
    }
 
  }}
  >
   {
      ActivityIndicatorVisible?<ActivityIndicator size="small" color="#fff" />:<Text style={styles.button}>
    تغيير كلمة المرور
    </Text>
    }
  </TouchableOpacity>
  <TouchableOpacity
  style={styles.signup}
  onPress={()=>{
    navigation.replace("landing")
  }}
  >
    <Text style={styles.signupbutton}>
      العودة
    </Text>
  </TouchableOpacity>

     </View>

    </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  settingContainer: {
    flexDirection: 'row-reverse',
    justifyContent:"space-between",
    alignItems: 'center',
    paddingVertical: 20,
  },
  settingText: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Cairo_400Regular",

  },
  titleText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: "Cairo_700Bold",

  },
  arrow:{
    width:15,
    height:15
  },
  header:{
    textAlign:"center",
    fontFamily: "Cairo_700Bold",
    fontSize:30,
    color:"#E63946dd",
    marginBottom:50
  },
  input:{
    textAlign:"right",
    fontFamily: "Cairo_400Regular",
    padding:10,
    width:"90%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
  },
  login:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
    backgroundColor:"#E63946dd"
  },
  signup:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
  },
  button:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"white"
  },
  signupbutton:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"#E63946dd"
  },
  Modal:{
    backgroundColor:"white",
    alignItems:"center",
    padding:20,
    borderRadius:20
  },
  ModalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20
  },
});

export default ForgotPassword;
