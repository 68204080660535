import React from 'react';
import { StyleSheet, View ,Image} from 'react-native';
//import { Button, Card, Modal, Text } from '@ui-kitten/components';
import { Divider,Button, Card, Text  } from "react-native-paper"
import Modal from "react-native-modal";

export const ErrorModal = ({msg,setVisible}) => {

  const [visible1, setVisible1] = React.useState(true);

  return (
    <Modal
    visible={visible1}
    backdropStyle={styles.backdrop}
    onBackdropPress={() => setVisible(false)}>
    <Card disabled={true}>
    <Image source={require('./assets/error.png')} 
              style={{ width: 80, height: 80,marginLeft:"auto",marginRight:"auto",marginTop:20 }}

      />
      <Text style={styles.modalText}>{msg}</Text>
      
    </Card>
  </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20,
    textAlign:"center"
  }
});