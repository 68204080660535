import React, { useState,useEffect } from 'react';
import { ScrollView, StyleSheet,RefreshControl,TouchableOpacity  } from 'react-native';
import { View ,Text,StatusBar} from 'react-native';
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons,FontAwesome,Feather  } from '@expo/vector-icons'; 
import {
    LineChart,PieChart
  } from "react-native-chart-kit";
  import { Dimensions } from 'react-native';
import Apis from './Apis';
import { SkeletonView } from "react-native-ui-lib";
import OrderCompleteCartSlider from "./OrderCompleteCartSlider";
import OrderCompleteCartItemHome from './OrderCompleteCartItemHome';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Popup } from 'react-native-popup-confirm-toast'
import Toast from 'react-native-toast-message';

function HomeTab({navigation}) {
    const [orders,setOrders]=useState([])
    const [chart1Values,setChart1Values]=useState([0,0,0,0,0,0,0])
    const [last7Days,setLast7Days]=useState(["","","","","","",""])
    const [productsCount,setProductsCount]=useState(0)
    const [smsbalance,setSmsbalance]=useState(0)
    const [refreshing, setRefreshing] = useState(true);
    const [piChart,setPiChart]=useState([])
    const onRefresh = () => {
        setRefreshing(true);
        getOrders()
        getLast7Days()
        countProducts()
        calculatePiChart(orders)

      };
      function calculatePiChart(array) {
        let idCounts = {};
        let totalCount = 0;
      
        // Count the occurrence of each id
        array.forEach(item => {
          if (!idCounts[item.id]) {
            idCounts[item.id] = {
              count:0,
              name:item.name
            };
          }
          idCounts[item.id].count++;
          totalCount++;
        });
      
        // Calculate the percentage of each id
        var colors=["e63946","ffba08","4daa57","2f6690","9113a4"]
        let result = [];
        var i=0;
        Object.keys(idCounts).forEach(function(key) {
          i++
          if(i<=5){
            result.push({
              id: key,
              name:idCounts[key].name,
              percentage: (idCounts[key].count / totalCount) * 100,
              color:"#"+colors[i-1],
              
            });
          }
        
        });
       
      setPiChart([...result])
        
      }
   
    function getLast7Days(){
        var days = [];
        var date = new Date();
        var values=[0,0,0,0,0,0,0]
        for (var i = 0; i < 7; i++) {
          date.setDate(date.getDate() - 1);
          orders.forEach(order => {
            if (date.toDateString() === new Date(order.orderDate).toDateString()){
                values[i]+=order.price*order.quantity
            }
          });
          days.push(("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2));
        }
        setChart1Values([...values.reverse()])
        setLast7Days([...days.reverse()])
        return days
    }
    async function getOrders(){
        const response = await Apis.getStoreOrders();
        if(response.status=="success"){
            setOrders([...response.data])
            setRefreshing(false)

        }
    }

    async function countProducts(){
      const response=await Apis.countProducts()
      if(response.status=="success"){
          setProductsCount(response.data[0].productsCount)
      }
  }
  async function getSmsBalance(){
    const response=await Apis.getSmsBalance()
    if(response.status=="success"){
       setSmsbalance(response.data[0].smsbalance)
    }
}

    useEffect(()=>{
      getSmsBalance()
        getOrders()
        getLast7Days()
        countProducts()
        calculatePiChart(orders)


    },[])
    useEffect(()=>{
        
        getLast7Days()
        calculatePiChart(orders)

    },[orders])
  return (
    <>
     {/*<TouchableOpacity
        style={{
            position:"absolute",
            zIndex:9999,
            elevation:9999,
            bottom:30,
            left:20
            }}

            onPress={async()=>{
              
              await AsyncStorage.setItem("token","")

              //alert(JSON.stringify(response))
               navigation.reset({
                      index: 0,
                      routes: [{ name: 'landing' }],
                    }); 
            }}
        >
        <View
        style={{
            width:50,
            height:50,
            backgroundColor:"#E63946dd",
            borderRadius:25,
            alignItems:"center",
            justifyContent:"center"
        }}
        >
  <MaterialIcons name="logout" color={"#ffffffdd"} size={30} />

        </View>
      </TouchableOpacity>*/}


    <View style={{width:"100%",height:"100%",backgroundColor:"#fff"}}>

<View style={{width:"90%",height:"100%",marginLeft:"auto",marginRight:"auto"}}>

<ScrollView
showsVerticalScrollIndicator={false}
style={{paddingTop:30}}
refreshControl={
    <RefreshControl refreshing={refreshing} onRefresh={onRefresh}  tintColor="#E63946dd" />
  }
>
  {
    refreshing?<SkeletonView
    template={SkeletonView.templates.LIST_ITEM}
    times={10}
    circle={true}
  
  />:<View style={{marginBottom:70}}>
  <View style={{padding:10,backgroundColor:"#FA6D52",borderRadius:16,flexDirection:"row",marginBottom:10}}>
  <Ionicons name="wallet-outline" color={"#ffffffdd"} size={50} style={{marginTop:"auto",marginBottom:"auto",marginLeft:30}}/>
  
      <View style={{marginLeft:"auto"}}>
      <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:35,color:"#ffffffdd"}}>
  {smsbalance}
  </Text>
  <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:18,color:"#ffffffdd"}}>
 رصيد ال SMS  
  </Text>
  
      </View>
  
  
  </View>
  <View style={{padding:10,backgroundColor:"#E63946dd",borderRadius:16,flexDirection:"row",marginBottom:10}}>
  <Ionicons name="ios-cart-outline" color={"#ffffffdd"} size={50} style={{marginTop:"auto",marginBottom:"auto",marginLeft:30}}/>
  
      <View style={{marginLeft:"auto"}}>
      <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:35,color:"#ffffffdd"}}>
  {orders.length}
  </Text>
  <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:18,color:"#ffffffdd"}}>
  عدد الطلبات الكلي
  </Text>
  
      </View>
  
  
  </View>
  
  <View style={{padding:10,backgroundColor:"#ffba08dd",borderRadius:16,flexDirection:"row",marginBottom:10}}>
  <MaterialIcons name="add-shopping-cart" color={"#ffffffdd"} size={50} style={{marginTop:"auto",marginBottom:"auto",marginLeft:30}}/>
  
      <View style={{marginLeft:"auto"}}>
      <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:35,color:"#ffffffdd"}}>
  {orders.filter(order => order.storestatus == 0).length}
  </Text>
  <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:18,color:"#ffffffdd"}}>
  الطلبات الجديدة
  </Text>
  
      </View>
  
  
  </View>
  
  <View style={{padding:10,backgroundColor:"#2f6690dd",borderRadius:16,flexDirection:"row",marginBottom:10}}>
  <FontAwesome name="money" color={"#ffffffdd"} size={50} style={{marginTop:"auto",marginBottom:"auto",marginLeft:30}}/>
  
      <View style={{marginLeft:"auto"}}>
      <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:35,color:"#ffffffdd"}}>
      ₪ {orders.reduce((acc, obj) => {
        if(obj.adminstatus!==4&&obj.adminstatus!==5)
        return acc + (obj.price*obj.quantity)
      
        else return acc+0
      }, 0)
        }
  </Text>
  <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:18,color:"#ffffffdd"}}>
  مجموع المبيعات</Text>
  
      </View>
  
  
  </View>
  
  <View style={{padding:10,backgroundColor:"#4daa57dd",borderRadius:16,flexDirection:"row",marginBottom:10}}>
  <Feather name="box" color={"#ffffffdd"} size={50} style={{marginTop:"auto",marginBottom:"auto",marginLeft:30}}/>
  
      <View style={{marginLeft:"auto"}}>
      <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:35,color:"#ffffffdd"}}>
  {productsCount}
  </Text>
  <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:18,color:"#ffffffdd"}}>
  عدد المنتجات
  </Text>
  
      </View>
  
  
  </View>
  
  <View>
  <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:14,color:"#000000cc"}}>
  
  المبيعات اليومية ( آخر 7 أيام )</Text>
  
    <LineChart
    segments={6}
      data={{
        labels: last7Days,
        datasets: [
          {
            data: chart1Values
          }
        ]
      }}
      width={Dimensions.get("window").width*0.9} // from react-native
      height={220}
      yAxisLabel="₪ "
      yAxisInterval={1} // optional, defaults to 1
      chartConfig={{
        backgroundColor: "#E63946dd",
        backgroundGradientFrom: "#E63946",
        backgroundGradientTo: "#E63946",
        backgroundGradientToOpacity:0.8,
        decimalPlaces: 0, // optional, defaults to 2dp
        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        style: {
          borderRadius: 16
        },
        propsForDots: {
          r: "6",
          strokeWidth: "2",
          stroke: "#E63946dd"
        }
      }}
      bezier
      style={{
        marginVertical: 8,
        borderRadius:16
      }}
    />
  </View>
  <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:14,color:"#000000cc"}}>
  
  آخر 5 طلبيات
  </Text>
  {
          orders.map(function(item, index) {
            if (index < 5) {
              return <OrderCompleteCartItemHome navigation={navigation} product={item} onchange={(type,msg)=>{
                getOrders()
                getLast7Days()
                countProducts()

                type=="success"?(
                  Toast.show({
                    type: 'success',
                    text1:msg
                  }) ):(
                    Toast.show({
                      type: 'error',
                      text1:msg
                    }) )
              
              }}/>;
            }
          }
            )
        }
  
  <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:14,color:"#000000cc"}}>
  
  أكثر المنتجات مبيعاً
  </Text>
        <PieChart
    data={piChart}
    width={Dimensions.get("window").width*0.9}
    height={220}
    chartConfig={{
     
      color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      propsForHorizontalLabels:{
        fontFamily:"Cairo_400Regular",
        }
    }}
    
    accessor={"percentage"}
    backgroundColor={"transparent"}
    center={[10, 10]}
    avoidFalseZero={true}
    legendConfig={
      {
        labelFontFamily: 'Cairo_400Regular',

      }
    }  
    
  />
  </View>
  
  }


</ScrollView>

</View>
    </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  settingContainer: {
    flexDirection: 'row-reverse',
    justifyContent:"space-between",
    alignItems: 'center',
    paddingVertical: 20,
  },
  settingText: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Cairo_400Regular",

  },
  titleText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: "Cairo_700Bold",

  },
  arrow:{
    width:15,
    height:15
  },
  header:{
    textAlign:"center",
    fontFamily: "Cairo_700Bold",
    fontSize:20,
    color:"#E63946dd",
    marginBottom:50
  },
  input:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    padding:10,
    width:"50%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
  },
  login:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
    backgroundColor:"#E63946dd"
  },
  signup:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
  },
  button:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"white"
  },
  signupbutton:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"#E63946dd"
  },
  Modal:{
    backgroundColor:"white",
    alignItems:"center",
    padding:20,
    borderRadius:16
  },
  ModalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20
  },
});

export default HomeTab;
