
import React,{useState,useEffect} from "react";
import { View,Text, TouchableOpacity,Modal, ScrollView } from "react-native";
const ColorsSelect = ({setProperties,id,colorsFromImage}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedColors,setSelectedColor]=useState([])
    const [colors,setColors] = useState([
        
        "#ffffff", "#f2f2f2", "#e5e5e5", "#d9d9d9", "#cccccc", "#bfbfbf", "#b3b3b3",
        "#ffe5e5", "#ffcaca", "#ffb6b6", "#ff9c9c", "#ff7373", "#ff4040", "#ff0000",
        "#ffffe0", "#ffffb3", "#ffff80", "#ffff66", "#ffff33", "#ffff1a", "#ffff00",
        "#ffe066", "#ffeb3b", "#fff44f", "#ffd700", "#ffc925", "#ffb347", "#ffa07a",
        "#90ee90", "#32cd32", "#00ff00", "#00fa9a", "#3cb371", "#228b22", "#2e8b57",
        "#87ceeb", "#1e90ff", "#4169e1", "#0000ff", "#4682b4", "#5f9ea0", "#708090",
        "#dda0dd", "#9370db", "#ba55d3", "#9932cc", "#8a2be2", "#9400d3", "#800080",
        "#a8a8a8", "#8c8c8c", "#707070", "#555555", "#383838", "#1c1c1c", "#000000"
      ]);


 
      useEffect(()=>{

        setProperties(id,selectedColors)

      },[selectedColors])
      useEffect(()=>{

        const uniqueArray = [...new Set([...colorsFromImage,...colors])];
        setColors([...uniqueArray])
      },[])
    return(

        <>
                <Text
    style={{fontFamily:"Cairo_400Regular",textAlign:"center"}}
    >
    
قم بالضغط على اشارة + لاضافة الالوان المتاحة لهذه الخاصية        
        </Text>
        <View style={{
            alignItems:"flex-end",
            
        }}>
                <View
    style={{
        flexDirection:"row-reverse",
        flexWrap:"wrap"
    }}>

    {
        selectedColors.map(color=>{

            return(
                <>
                <View
                style={{width:30,height:30,backgroundColor:color,borderRadius:15,marginHorizontal:5,marginVertical:5,borderWidth:color=="#ffffff"?0.5:0,borderColor:"black"}}
                >

                </View>
                </>
            )
        })
        
    }
   <TouchableOpacity
    onPress={()=>{

        setModalVisible(true)
    }}
   >
            <View style={{width:30,height:30,borderWidth:1,borderRadius:15,marginHorizontal:5,marginVertical:5,justifyContent:"center",alignItems:"center"}}>
        
<Text>
    +
</Text>
</View>

            </TouchableOpacity>
    </View>
         

          
             <Modal animationType="slide"
        transparent={true}
        visible={modalVisible}
     >
          
                  <View
  style={{height:"100%",alignItems:"center",justifyContent:"center",backgroundColor:"#00000044"}}
  >
    <View style={{
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        height:"90%"
    }}>

<ScrollView>
<Text
    style={{fontFamily:"Cairo_400Regular",textAlign:"center"}}
    >
        قم باختيار الألوان المتوفرة لديك لهذا المنتج حتى يتمكن المستخدم من اختيار اللون في صفحة المنتج
        
        </Text>
        <Text
    style={{fontFamily:"Cairo_400Regular",textAlign:"center"}}
    >
    ( إضغط على اللون لاختياره واضغط مرة أخرى لحذفه )        
        </Text>
<Text
    style={{fontFamily:"Cairo_700Bold",textAlign:"center",marginTop:20}}
    >اختر الألوان</Text>
    <View
    style={{
        flexDirection:"row-reverse",
        flexWrap:"wrap"
    }}>
   
    {
        
        colors.map(color=>{

            return(
                <>
                <TouchableOpacity
                onPress={()=>{
                    var colorsAr=selectedColors
                    const index = colorsAr.indexOf(color);
                    if (index === -1) {
                        colorsAr.push(color);
                    } else {
                        colorsAr.splice(index, 1);
                    }          
                              setSelectedColor([...colorsAr])
                }}
                
                >
                      <View
                style={{width:30,height:30,backgroundColor:color,borderRadius:15,marginHorizontal:5,marginVertical:5,borderWidth:color=="#ffffff"?0.5:0,borderColor:"black"}}
                >

                </View>
                </TouchableOpacity>
              
                </>
            )
        })
    }

    </View>
    <Text
    style={{fontFamily:"Cairo_700Bold",textAlign:"center"}}
    >الألوان المختارة</Text>
      <View
    style={{
        flexDirection:"row-reverse",
        flexWrap:"wrap"
    }}>

    {
        selectedColors.map(color=>{

            return(
                <>
                  <TouchableOpacity
                onPress={()=>{
                    var colorsAr=selectedColors
                    const index = colorsAr.indexOf(color);
                    if (index === -1) {
                        colorsAr.push(color);
                    } else {
                        colorsAr.splice(index, 1);
                    }          
                              setSelectedColor([...colorsAr])
                }}
                
                >
                      <View
                style={{width:30,height:30,backgroundColor:color,borderRadius:15,marginHorizontal:5,marginVertical:5,borderWidth:color=="#ffffff"?0.5:0,borderColor:"black"}}
                >

                </View>
                </TouchableOpacity>
                </>
            )
        })
    }

    </View>
  
    </ScrollView>
    <TouchableOpacity
    onPress={()=>{

        setModalVisible(false)
    }}
    >
        <View
        style={{
            width:"100%",
            marginLeft:"auto",
            marginRight:"auto",
            backgroundColor:"#E63946dd",
            padding:10,
            borderRadius:20,
            width:100
        }}
        >
            <Text
                style={{color:"white",fontFamily:"Cairo_700Bold",textAlign:"center"}}

            >
                حفظ
            </Text>
        </View>
    </TouchableOpacity>
    </View>

          </View>


        </Modal>
        </View>
 
      
        </>
    )

}

export default ColorsSelect