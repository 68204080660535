import React from 'react';
import { View, FlatList  } from 'react-native';
import OrderCompleteCartItem from './OrderCompleteCartItem';

function OrderCompleteCartSlider({products,onchange,navigation}) {
  return (
    <View>
      {
        products.map(item=>
          <OrderCompleteCartItem navigation={navigation} product={item} onchange={onchange}/>
          )
      }
     
    
    </View>
  );
}

const styles = {
  row: {
    flexDirection: 'row',
  },
};

export default OrderCompleteCartSlider;
