import React, { useState,useEffect,useCallback } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet,ScrollView,RefreshControl,TextInput } from 'react-native';
import {SkeletonView} from 'react-native-ui-lib';
import { Divider } from "react-native-paper"
import OrderTable from './OrdersTable';
import Apis from './Apis';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function OrdersAll({navigation}) {
  const [isOrdersAvailable,setIsOrdersAvailable]=useState(false)
  const [refreshing, setRefreshing] = useState(false);
  const [viewSwitch, setViewSwitch] = useState(false);
  const [orders,setOrders]=useState([])
  const [ordersAll,setOrdersAll]=useState([])
  const [que,setque]=useState("")

  const handleViewSwitch=(event)=>{
    setViewSwitch(event.target.checked);

  }
  const handleTextInputChange = (text) => {
    setque(text);
 

    setOrders(searchOrders(text))
  };
  function searchInObject(obj, searchTerm) {
    for (const key in obj) {
      const value = obj[key];
  
      if (typeof value === 'object' && value !== null) {
        // If the value is an object, recursively search within the object
        if (searchInObject(value, searchTerm)) {
          return true;
        }
      } else {
        // If the value is not an object, check if it includes the search term
        if (
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return true;
        }
      }
    }
  
    return false;
  }
  
  // Function to search for a value in the JSON array
  function searchOrders(searchTerm) {
    return ordersAll.filter(order => searchInObject(order, searchTerm));
  }
  async function getPreviousOrders(){

    const response=await Apis.getPreviousOrders()
    //console.log("response")

    if(response.status=="success"){
      setOrdersAll([...response.data])
      setOrders([...response.data])
console.log(response.data)
    }
    setIsOrdersAvailable(true)

  }


  useEffect(()=>{

getPreviousOrders()
  },[])
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setIsOrdersAvailable(false)
   
    getPreviousOrders()
    setRefreshing(false)

 
  }, []);


  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'},
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));


  return (
    <View style={styles.container}>
      <TextInput
          
          value={que}
          onChangeText={(text) => handleTextInputChange(text)}
        
        placeholder="ابحث حسب رقم الطلبية او اسم او رقم المنتج" style={styles.searchInput}/>
  
  <View
  style={{flexDirection:"row-reverse"}}
  >
 <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:16,color:"#000000cc"}}>

عدد الطلبيات : {orders.length}  
  </Text>
  <View style={{flexDirection:"row-reverse",marginRight:"auto"}}>
      <Text 
  style={{
    marginRight:"auto",
    fontFamily: "Cairo_700Bold",
    marginTop:"auto",
    marginBottom:"auto"
  }}
  >
   {viewSwitch?"موبايل":"حاسوب"}
   </Text>
  <FormGroup style={{
    marginRight:"auto"
  }}>
      <FormControlLabel
        control={<MaterialUISwitch sx={{ m: 1 }}  checked={viewSwitch}
        onChange={handleViewSwitch} />}
        style={{
          fontFamily: "Cairo_400Regular", 

        }}
      />
 
    </FormGroup>
  </View>

    
{
  /**
   * 
   *   <Layout style={{marginLeft:"auto"}}>
      <OverflowMenu
      style={{maxHeight:999}}
        anchor={renderFilterButton}
        visible={filterVisible}
        selectedIndex={selectedIndex}
        onSelect={onItemSelect}
        onBackdropPress={() => setFilterVisible(false)}>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الكل</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات الجديدة</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات الجاهزة للشحن</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات المشحونة</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الكميات المنتهية</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات الملغية</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات المرتجعة</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات المسلمة</Text>)}/>
  
      </OverflowMenu>
    </Layout>
   */
}

  </View>
 
 {
  viewSwitch?<>
    {
        orders.length>0&&(
            <ScrollView
      showsVerticalScrollIndicator={false}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={"#E63946dd"}  colors={["#E63946dd"]}/>
      }
      >


{


isOrdersAvailable?(

  orders.map(item=>{
   // console.log(item)
    return(
      <>
<TouchableOpacity style={styles.settingContainer} onPress={() => {  navigation.push("previousorder",{
  products:item.cart,
  status:item.status,
  estimatedShippingDate:item.estimatedShippingDate,
  orderData:item
})}}>
  <View style={{flexDirection:"row-reverse"}}>

        <View style={{width:"90%"}}>
          <View style={{flexDirection:"row-reverse",alignContent:"flex-start",marginBottom:10 }}>
        
      <Text style={[styles.settingText,{marginTop:"auto",marginBottom:"auto",fontFamily:"Cairo_700Bold"}]}>{item.orderId}#</Text>
      <View>

      </View>
          </View>
        <Text style={styles.settingText}>{item.orderDate}</Text>
        <Text style={styles.settingText}>عدد المنتجات : {item.cart.length} منتج</Text>
        <Text style={styles.settingText}>المجموع : {item.subtotal} ₪</Text>
        <Text style={styles.settingText}>الشحن : {item.shipping} ₪</Text>
        <Text style={styles.settingText}>المجموع الكلي : {item.total} ₪</Text>
        <Text style={styles.settingText}>عنوان الشحن : {item.address}</Text>


  <View style={{flexDirection:"row-reverse",flexWrap:"wrap"}}>
  {
  item.cart.map(image=>{
    return(
<Image
style={{width:60,height:90,marginHorizontal:10,marginVertical:10}}
source={{uri:image.image.uri}}
/>

    )
  })
  
  
}
    </View>

        </View>
      
    
        <View style={{width:"10%",flexDirection:"row"}}>

        <Image source={require('./assets/left-arrow.png')} style={styles.arrow} />
        
</View>

</View>
      </TouchableOpacity>
      <Divider/>

      </>
    )
  })
)
:<SkeletonView
template={SkeletonView.templates.LIST_ITEM}
times={10}
circle={true}

/>


}

      </ScrollView>
  )
      }
      {
        orders.length==0&&(

          isOrdersAvailable? <View
          style={{marginTop:"auto",marginBottom:"auto",marginLeft:"auto",marginRight:"auto"}}
          >
          <Image
          source={require("./assets/cargo.png")}
          style={{width:200,height:200,marginLeft:"auto",marginRight:"auto",marginBottom:20}}
          />
          <Text
          style={{
            marginLeft:"auto",
            marginRight:"auto",
            textAlign:"center",
            color:"#CDCDCD",
            fontFamily: "Cairo_700Bold",
          }}
          >
            ليس لديك أي طلبات
          </Text>
          <Text
          style={{
            marginLeft:"auto",
            marginRight:"auto",
            textAlign:"center",
            color:"#CDCDCD",
            fontFamily: "Cairo_700Bold",
          }}
          >
          ستظهر الطلبات هنا عندما يقوم احد زبائنك بالطلب
          </Text>
          </View>
          :<SkeletonView
        template={SkeletonView.templates.LIST_ITEM}
        times={10}
        circle={true}
      
      />
         
        )
      }
    
  </>:<>
    <View style={{ flex: 1, padding: 10 }}>
      <OrderTable orders={orders} getPreviousOrders={getPreviousOrders}/>
    </View>
  </>
 }


    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  settingContainer: {
    flexDirection: 'row-reverse',
    justifyContent:"space-between",
    alignItems: 'center',
    paddingVertical: 20,
  },
  settingText: {
    fontSize: 13,
    marginLeft: 10,
    fontFamily: "Cairo_400Regular", 
    textAlign:"right"

  },
  titleText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: "Cairo_700Bold",

  },
  arrow:{
    width:20,
    height:20,
    marginTop:"auto",
    marginBottom:"auto"
  },
  searchInput: {
    width: "100%",
    backgroundColor: "#0000000d",
    marginLeft: "auto",
    marginRight: "auto",
    padding: 13,
    borderRadius: 10,
    fontFamily: "Cairo_400Regular",
    textAlign:"right"
  },
});

export default OrdersAll;
