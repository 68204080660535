import React,{useState} from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { FontAwesome } from '@expo/vector-icons'; 
import { ProductActions } from './ProductActions';

const ProductCard = ({ product,navigation }) => {
  const [actionsModalVisible,setActionsModalVisible]=useState(false)

  return (
    <> 
    {
      actionsModalVisible&& <ProductActions special={product.special==1?true:false} id={product.id} notactivated={product.rejected==1?false:product.underreview==1?false:product.activated==0?true:false} setVisible={setActionsModalVisible} navigation={navigation}/>
    }
       <TouchableOpacity style={styles.cardContainer}

    onPress={()=>{
      setActionsModalVisible(true)
    }}
    
    >
      <View
      style={{flexDirection:"row-reverse"}}
      >
 <View style={styles.imageContainer}>
       
       <Image source={product.image} style={styles.image} />
     </View>
     <View
     style={{
      marginRight:20,
     }}
     >
      <View
      style={{flexDirection:"row-reverse",alignItems:"center"}}
      >
     <Text style={styles.pid} numberOfLines={1} ellipsizeMode='tail'>{product.id} #</Text>
     {
      product.special==1&&(<>
           <FontAwesome name="star" size={15} color="gold" />

      </>)
     }
      </View>
     <Text style={styles.name} numberOfLines={1} ellipsizeMode='tail'>{product.name}</Text>
      <Text style={styles.category} numberOfLines={1} ellipsizeMode='tail'>{product.maincatname} - {product.category}</Text>
      <View style={styles.priceContainer}>
        <Text style={styles.price}>₪{product.price}</Text>
        {product.discount!=0 && (
          <Text style={styles.discount}>₪{product.discount}</Text>
        )}
      </View>
      <Text style={styles.category}>عدد المشاهدات: {product.views}</Text>
      <Text style={styles.category}>الكمية المتوفرة: {product.quantity}</Text>

     </View>
     {
        <View
   style={{ 
     marginLeft:"auto",
     justifyContent:"center",
  }}
    >
<View
 style={{
  padding:5,
  backgroundColor: product.rejected==1?"#e63946dd":product.underreview==1?"#2f6690dd":product.activated==0?"#e63946dd":"#4daa57dd",
  borderRadius:5,
}}
>
<Text
          style={{textAlign:"center",color:"#fff",fontFamily: "Cairo_400Regular",}}>
        {product.rejected==1?"مرفوض":product.underreview==1?"جار المراجعة":product.activated==0?"غير فعال":"فعال"}  
          </Text>
        
</View>
{
  product.quantity<=0&&(

    <View
 style={{
  padding:5,
  backgroundColor:"#e63946dd",
  borderRadius:5,
  marginTop:10
}}
>
          <Text
          style={{textAlign:"center",color:"#fff",fontFamily: "Cairo_400Regular"}}>
        الكمية منتهية  
          </Text>
</View>
  )
}

          
    </View>
      
     }
    
      </View>
     
    
    </TouchableOpacity>
    </>

  );
};

const styles = StyleSheet.create({
  cardContainer: {
    flex:1,
    margin: 8,
  
  },
  imageContainer: {
    position: 'relative',
  },
  favoriteIcon: {
    position: 'absolute',
    top: 8,
    left: 8,
    zIndex:1
  },
  image: {
    width:100,
    maxHeight:200,
   aspectRatio:2/3,
    borderRadius: 8,
  },
  name: {
    fontSize: 14,
    paddingLeft: 8,
    fontFamily: "Cairo_700Bold",
    textAlign:"right",
    marginLeft:"auto",
    maxWidth:160

  },
  pid: {
    fontSize: 14,
    paddingLeft: 8,
    fontFamily: "Cairo_700Bold",
    textAlign:"right",

  },
  category: {
    fontSize: 12,
    color: 'gray',
    paddingLeft: 8,
    fontFamily: "Cairo_400Regular",
    textAlign:"right"

  },
  priceContainer: {
    flexDirection:'row-reverse',
    alignItems: 'center',
    fontFamily: "Cairo_400Regular"
  },
  price: {
    fontSize: 14,
    fontFamily: "Cairo_700Bold",
    paddingLeft: 8,
    textAlign:"right"

  },
  discount: {
    fontSize: 14,
    color: 'gray',
    textDecorationLine: 'line-through',
    fontFamily: "Cairo_400Regular",
    paddingLeft: 8,
    textAlign:"right"

  },
});

export default ProductCard;
