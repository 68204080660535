import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet,TextInput,TouchableWithoutFeedback, Keyboard,ActivityIndicator  } from 'react-native';
import { Divider } from "react-native-paper"
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Apis from './Apis';
import { Dialog ,PanningProvider} from 'react-native-ui-lib';
import { ErrorModal } from './ErrorModal';
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import AWS from "aws-sdk/dist/aws-sdk-react-native";
import * as ImageManipulator from 'expo-image-manipulator';
import { Buffer } from "buffer"; // import buffer

function SignUp({navigation}) {

    const [signUpStoreName,setSignUpStoreName]=useState("")
    const [signUpOwnerName,setSignUpOwnerName]=useState("")
    const [signupAddress,setSignUpAddress]=useState("")
    const [signUpCategory,setSignUpCategory]=useState("")
    const [signUpMobile,setSignUpMobile]=useState("")
    const [signupPassword,setSignUpPassword]=useState("")
    const [signUpConfirmPassword,setSignUpConfirmPassword]=useState("")
    const [errorModalVisible,setErrorModalVisible]=useState(false)
    const [signUpDisabled,setSignUpDisabled]=useState(false)
    const [errorModalMessage,setErrorModalMessage]=useState("")
    const [firstImage, setFirstImage] = useState(null);
    const [getItem, setGetItem] = useState(false);
    const [mainImageS3, setMainImageS3] = useState(null);

    function makeotp(length) {
      var result = "";
      var characters = "0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  
    const handleFirstImage = async () => {

      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status === "granted") {
        const result = await ImagePicker.launchImageLibraryAsync({
          allowsEditing: false,
          aspect: [3, 4],
          base64: true,
        });
        if (!result.canceled) {
          var id = makeotp(5);
          const compressedImage = await ImageManipulator.manipulateAsync(
            result.assets[0].uri,
            [{ resize:{
            
              width:500
            } }],
            {base64:true, format: 'jpeg' }
          );
          setFirstImage({ id: id, uri: compressedImage.uri });
          uploadToS3(id, compressedImage.base64);
        }
      }
    };
    const uploadToS3 = (id, base64) => {
      setGetItem(false)

      AWS.config.update({
        accessKeyId: "AKIA3OW6HHJV4QMX3ONA",
        secretAccessKey: "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",
      });
  
      const s3 = new AWS.S3();
      const file = {
        name: makeotp(10) + ".jpg",
        type: "image/jpeg",
      };
  
      const params = {
        Bucket: "shineapp",
        Key: "images/" + file.name,
        Body: new Buffer(base64, "base64"),
        ContentType: file.type,
        ACL: "public-read",
      };
  
      s3.upload(params, (err, data) => {
        if (err) {
         
         // console.log(err, err.stack);
        } else {
       //   console.log(data);
          setMainImageS3({ id, uri: data.Location });
          setGetItem(true)
        }
      })
    };
  
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
       
    <View style={styles.container}>
    {
      errorModalVisible&& <ErrorModal msg={errorModalMessage} setVisible={setErrorModalVisible}/>
    }
   
    <KeyboardAwareScrollView
    showsVerticalScrollIndicator={false}
    >

    
   
     <View style={{marginTop:100}}>
   
     <Text style={styles.header}>
       تسجيل حساب جديد
    </Text>
    <Text
    style={{textAlign:"center",marginVertical:10,fontFamily: "Cairo_700Bold"}}
    >
      لوغو المتجر
    </Text>
    <TouchableOpacity
                onPress={()=>{
                  handleFirstImage()

                }}
                >
                  <View
                    style={{
                      width: 100,
                      aspectRatio: 1 / 1,
                      marginLeft:"auto",
                      marginRight:"auto",
                      alignItems: "center",
                      borderWidth: firstImage?0:0.5,
                      borderRadius: 50,
                      marginHorizontal: 10,
                      shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 4,
},
shadowOpacity: 0.30,
shadowRadius: 4.65,
                    }}
                  >
                    {
                      firstImage?
                      <> 
                       <Image
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      borderRadius: 50,

                    }}
                    source={{uri:firstImage.uri}}
                  />
                    {
                !getItem&&(
                   <View 
              style={{position:"absolute",alignContent:"center",alignItems:"center",justifyContent:"center",width:"100%",height:"100%",backgroundColor:"#ffffff99"}}
              >
              <ActivityIndicator size="large" color="#E63946dd"/>
              
              </View>
                )
              }
                      </>
                  
                  
                      :
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        source={require("./assets/plus.png")}
                      />
                    }
                  </View>
                </TouchableOpacity>

    <TextInput
      placeholder="اسم المتجر"
      onChangeText={text => setSignUpStoreName(text)}

  value={signUpStoreName}
  style={styles.input}

  />
    <TextInput
      placeholder="اسم صاحب المتجر"
      onChangeText={text => setSignUpOwnerName(text)}

  value={signUpOwnerName}
  style={styles.input}

  />
    <TextInput
    keyboardType='phone-pad'
      placeholder="رقم الهاتف"
      onChangeText={text => setSignUpMobile(text)}

  value={signUpMobile}
  style={styles.input}
    maxLength={10}
  />
    <TextInput
      placeholder="تصنيف المتجر : ملابس, أجهزة كهربائية ... الخ"
      onChangeText={text => setSignUpCategory(text)}

  value={signUpCategory}
  style={styles.input}

  />
    <TextInput
      placeholder="عنوان المتجر"
      onChangeText={text => setSignUpAddress(text)}

  value={signupAddress}
  style={styles.input}

  />
    <TextInput
      placeholder="كلمة المرور"
      onChangeText={text => setSignUpPassword(text)}
    secureTextEntry
  value={signupPassword}
  style={styles.input}
  
  />
      <TextInput
      placeholder="تأكيد كلمة المرور"
      onChangeText={text => setSignUpConfirmPassword(text)}
    secureTextEntry
  value={signUpConfirmPassword}
  style={styles.input}
  
  />

  
  <TouchableOpacity
  style={styles.login}

  disabled={signUpDisabled}
  onPress={async()=>{

    setSignUpDisabled(true)
    if(!signUpStoreName||!signUpMobile||!signupPassword||!signUpConfirmPassword||!signUpOwnerName||!signUpCategory||!signupAddress||!mainImageS3){
      setErrorModalVisible(true)
      if(!mainImageS3)
      setErrorModalMessage("لوغو المتجر اجباري")
      else
      setErrorModalMessage("جميع الحقول مطلوبة")

      setSignUpDisabled(false)

    }
    else if(signUpConfirmPassword!==signupPassword){

      setErrorModalVisible(true)
      setErrorModalMessage("كلمة المرور لا تطابق التأكيد")
      setSignUpDisabled(false)

    }
    else if(signUpMobile.length!==10){
      setErrorModalVisible(true)
      setErrorModalMessage("رقم الهاتف يجب أن يحتوي على 10 أرقام")
      setSignUpDisabled(false)

    }
    else{
       const response=await Apis.signup(signUpStoreName,signUpOwnerName,signupAddress,signUpMobile,signUpCategory,mainImageS3.uri,signupPassword)
    setSignUpDisabled(false)

    if(response.status=="success"){
      navigation.replace("otp",{
        mobile:signUpMobile,
        target:"newaccount"
      })
    }
    else{
      setErrorModalVisible(true)
      setErrorModalMessage(response.msg)
    }
    }

   
  }}
  >
    {
      signUpDisabled?<ActivityIndicator size="small" color="#fff" />: <Text style={styles.button}>
      انشاء حساب
   </Text>
    }
   
  </TouchableOpacity>
  <TouchableOpacity
  style={styles.signup}
  onPress={()=>{
    navigation.replace("landing")
  }}
  >
    <Text style={styles.signupbutton}>
       تسجيل الدخول
    </Text>
  </TouchableOpacity>

     </View>

    </KeyboardAwareScrollView>
    </View>

    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  settingContainer: {
    flexDirection: 'row-reverse',
    justifyContent:"space-between",
    alignItems: 'center',
    paddingVertical: 20,
  },
  settingText: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Cairo_400Regular",

  },
  titleText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: "Cairo_700Bold",

  },
  arrow:{
    width:15,
    height:15
  },
  header:{
    textAlign:"center",
    fontFamily: "Cairo_700Bold",
    fontSize:30,
    color:"#E63946dd",
  },
  input:{
    textAlign:"right",
    fontFamily: "Cairo_400Regular",
    padding:10,
    width:"90%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10
  },
  login:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
    backgroundColor:"#E63946dd"
  },
  signup:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
  },
  button:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"white"
  },
  signupbutton:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"#E63946dd"
  },
  Modal:{
    backgroundColor:"white",
    alignItems:"center",
    padding:20,
    borderRadius:20
  },
  ModalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20
  },
});

export default SignUp;
