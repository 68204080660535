import React from 'react';
import { StyleSheet, View ,Image,TouchableOpacity} from 'react-native';
//import { Button, Card, Modal, Text } from '@ui-kitten/components';
import { Divider,Button, Card, Text  } from "react-native-paper"
import Modal from "react-native-modal";

export const ConfirmationModal = ({msg,onpress,setVisible}) => {

  const [visible1, setVisible1] = React.useState(true);

  return (
    <Modal
    visible={visible1}
    backdropStyle={styles.backdrop}
    onDismiss={() => setVisible(false)}>
    <Card disabled={true} style={{
      padding:20
    }}>
  
      <Text style={styles.modalText}>{msg}</Text>
      <View
      style={{flexDirection:"row-reverse",justifyContent:"center"}}
      >
  <TouchableOpacity
      onPress={async()=>{

     onpress()
     setVisible(false)

      }}
      style={{marginHorizontal:10}}
      >
          <View
          style={{
              marginLeft:"auto",
              marginRight:"auto",
              backgroundColor:"#2f6690",
              padding:10,
              borderRadius:10,
              marginTop:10,
              height:60,
              justifyContent:"center",
          }}
          >
            <Text
  style={{color:"white",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
  موافق
  </Text>
  
              
          </View>
      </TouchableOpacity>
      <TouchableOpacity
      onPress={async()=>{

        setVisible(false)
      }}
      style={{marginHorizontal:10}}

      >
          <View
          style={{
              marginLeft:"auto",
              marginRight:"auto",
              backgroundColor:"#E63946",
              padding:10,
              borderRadius:10,
              marginTop:10,
              height:60,
              justifyContent:"center",
          }}
          >
            <Text
  style={{color:"white",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
  الغاء
  </Text>
  
              
          </View>
      </TouchableOpacity>

      </View>
    </Card>
  </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20,
    textAlign:"center"
  }
});