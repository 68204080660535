import React, { useState,useEffect } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  ScrollView,
  Modal,
  Alert,
  StyleSheet
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import AWS from "aws-sdk/dist/aws-sdk-react-native";
//import { ActionSheet } from "react-native-ui-lib";
import { Text } from "react-native-ui-lib";
import { Buffer } from "buffer"; // import buffer
import { TextInput,RadioButton  } from 'react-native-paper';
import { SelectList } from 'react-native-dropdown-select-list'
import ColorsSelect from "./ColorsSelect";
import MTC from "./MTC";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Apis from "./Apis";
import Toast from 'react-native-toast-message';
import * as ImageManipulator from 'expo-image-manipulator';
import { ModalWithBackdropShowcase } from "./ErrorModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ConfirmationModal } from "./ConfirmationModal";
import { AddImageModal } from "./AddImageModal";

const ImageInputs = ({navigation}) => {
  const [firstImage, setFirstImage] = useState(null);
  const [pName, setPName] = useState(null);
  const [pQuantity, setPQuantity] = useState(1);
  const [pPrice, setPPrice] = useState(null);
  const [pDiscount, setPDiscount] = useState(null);
  const [secondImages, setSecondImages] = useState([]);
  const [mainImageS3, setMainImageS3] = useState(null);
  const [ImagesS3, setImagesS3] = useState([]);
  const [isMainImageFilterShown, setIsMainImageFilterShown] = useState(false);
  const [category,setCategory]=useState(null)
  const [subCategory,setSubCategory]=useState(null)
  const [properties,setProperties]=useState([])
  const [modalVisible, setModalVisible] = useState(false);
  const [propertyType,setPropertyType]=useState("color")
  const [propertyName,setPropertyName]=useState("")
  const [categories,setCategories]=useState([])
  const [subCategories,setSubCategories]=useState([])
  const [loading, setLoading] = useState(false)
  const [colorsFromImage,setColorsFromImage]=useState([])
  const [confirmationModalVisible,setConfirmationModalVisible]=useState(false)
  const [confirmationModalMessage,setConfirmationModalMessage]=useState("")
  const [onModalPress,setOnModalPress]=useState(()=>{})
  const [addImageModalVisible,setAddImageModalVisible]=useState(false)
  const [onAddImageModalPressCamera,setOnAddImageModalPressCamera]=useState(()=>{})
  const [onAddImageModalPressStudio,setOnAddImageModalPressStudio]=useState(()=>{})
  const [isaddCategoryModalVisible, setAddCategoryModalVisible] = useState(false);
  const [AddCatName, setAddCatName] = useState("");
  const [addCategoryImage, setAddCategoryImage] = useState(null);
  const [addCategoryImagePreview, setAddCategoryImagePreview] = useState(null);

  function makeotp(length) {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  const handleFirstImage = async () => {
    const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
   // console.log(status)

    //if (status === "granted") 
    {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false,
        aspect: [3, 4],
        base64: true,
      });
      if (!result.canceled) {
        var id = makeotp(5);
        const compressedImage = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          [{ resize:{
          
            width:500
          } }],
          {base64:true, format: 'jpeg' }
        );
        setFirstImage({ id: id, uri: compressedImage.uri });
        //uploadToS3(id, "main", compressedImage.base64);
        uploadToServer(id, "main", compressedImage.uri);

      }
      else
      {
       // console.log(result)
      }
    }
  };
  const handleAddCategory=async()=>{
    try {
      await Apis.addCategory(AddCatName, addCategoryImagePreview.uri);
      // Update the coupons list after adding
      getCategories()
      setAddCategoryImage(null)
      setAddCategoryImagePreview(null)
      setAddCatName("")
      setAddCategoryModalVisible(false)
    } catch (error) {
      console.error('Error adding coupon:', error);
    }
  }
  const handleAddCategoryImage = async () => {
    const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
   // console.log(status)

    //if (status === "granted") 
    {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false,
        aspect: [3, 4],
        base64: true,
      });
      if (!result.canceled) {
        var id = makeotp(5);
        const compressedImage = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          [{ resize:{
          
            width:500
          } }],
          {base64:true, format: 'jpeg' }
        );
        setAddCategoryImage({ id: id, uri: compressedImage.uri });
        //uploadToS3(id, "main", compressedImage.base64);
        uploadToServer(id, "addcategory", compressedImage.uri);

      }
      else
      {
       // console.log(result)
      }
    }
  };
  const handleFirstImageCamera = async () => {
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    if (status === "granted") 
    {
      const result = await ImagePicker.launchCameraAsync({
        allowsEditing: false,
        aspect: [3, 4],
        base64: true,
      });
      if (!result.canceled) {
        var id = makeotp(5);
        const compressedImage = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          [{ resize:{
          
            width:500
          } }],
          {base64:true, format: 'jpeg' }
        );
        setFirstImage({ id: id, uri: compressedImage.uri });
        //uploadToS3(id, "main", compressedImage.base64);
        uploadToServer(id, "main", compressedImage.uri);

      }
    }
  };

  const handleSecondImages = async () => {
    const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
   // if (status === "granted")
     {
      const results = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false,
        aspect: [3, 4],
        base64: true,

        allowsMultipleSelection: true,
        
      });
      if (!results.canceled) {
        if(results.assets.length>9-secondImages.length){

          Toast.show({
            type: 'error',
            text1:"الحد الاقصى هو 10 صور"
          })  
        }
        else{
          var images = [];

          var i=0;
          results.assets.forEach(async(image) => {
            var id = makeotp(5);
            const compressedImage = await ImageManipulator.manipulateAsync(
              image.uri,
              [{ resize:{
              
                width:500
              } }],
              {base64:true, format: 'jpeg' }
            );
           // console.log(compressedImage)
            images.push({ id: id, uri: compressedImage.uri });
  
            //uploadToS3(id, "images", compressedImage.base64);
            uploadToServer(id, "images", compressedImage.uri);

            i++;
            if(i==results.assets.length)
            setSecondImages([...secondImages,...images]);
          });
        
        }
    
      }
    }
  };
  const handleSecondImagesCamera = async () => {
    const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
    if (status === "granted") {
      const results = await ImagePicker.launchCameraAsync({
        allowsEditing: false,
        aspect: [3, 4],
        base64: true,
      });
      if (!results.canceled) {
        var id = makeotp(5);
        var images=secondImages
        const compressedImage = await ImageManipulator.manipulateAsync(
          results.assets[0].uri,
          [{ resize:{
          
            width:500
          } }],
          {base64:true, format: 'jpeg' }
        );
        images.push({ id: id, uri: compressedImage.uri })
        //uploadToS3(id, "images", compressedImage.base64);
        uploadToServer(id, "images", compressedImage.uri);
        setSecondImages([...images]);
      }
    }
  };
  const uploadToServer = async (id, type, base64) => {
 
    try {
      const responseupload = await Apis.uploadToServer({
        base64:base64
      })
//console.log(responseupload)
  if (responseupload.data.status === "success") {
    if (type == "main") {
      setMainImageS3({ id, uri: responseupload.location });
    } else if (type == "images") {
      var images=ImagesS3
      images.push({ id, uri: responseupload.location })
      setImagesS3([...images]);


    }
    else if(type=="addcategory"){
      setAddCategoryImagePreview({ id, uri: responseupload.location });

    }
  }
    
    } catch (error) {
      console.error("Error uploading to server:", error);
    }
  };
  const uploadToS3 = (id, type, base64) => {
    AWS.config.update({
      accessKeyId: "AKIA3OW6HHJV4QMX3ONA",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3();
    const file = {
      name: makeotp(10) + ".jpg",
      type: "image/jpeg",
    };

    const params = {
      Bucket: "shineapp",
      Key: "images/" + file.name,
      Body: new Buffer(base64, "base64"),
      ContentType: file.type,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
       
       // console.log(err, err.stack);
      } else {
      //  console.log(data);
        if (type == "main") {
          setMainImageS3({ id, uri: data.Location });
        } else if (type == "images") {
          var images=ImagesS3
          images.push({ id, uri: data.Location })
          setImagesS3([...images]);


        }
      }
    })
  };


function updateProperties(id,data){

  let updatedArr = properties.map(obj => {
    if (obj.id === id) {
      return {...obj, data: data};
    }
    return obj;
  });
  setProperties([...updatedArr])
}

function deleteProperty(id){
  let updatedArr = properties.filter(obj => obj.id !== id);


  setProperties([...updatedArr])

}
function deleteImage(id){

  setOnModalPress(()=>()=>{
    let updatedArr = secondImages.filter(obj => obj.id !== id);


      setSecondImages([...updatedArr])
    
       updatedArr = ImagesS3.filter(obj => obj.id !== id);
    
    
      setImagesS3([...updatedArr])
    
  })
        setConfirmationModalMessage("هل أنت متأكد أنك تريد حذف الصورة؟")
        setConfirmationModalVisible(true)

}
async function getCategories(){
  const response = await Apis.getCategories()
  if(response.status=="success"){
    var cats=[]
    response.data.forEach(cat => {
      cats.push({
        key:cat.id,
         value:cat.name
        },
      )
    });
    setCategories([...cats])
  }
}

async function getSubCategories(id){
  const response = await Apis.getSubCategories(id)
  if(response.status=="success"){
    var cats=[]
    response.data.forEach(cat => {
      cats.push({
        key:cat.id,
         value:cat.name
        },
      )
    });
    setSubCategories([...cats])
  }
}


async function uploadProduct(){
  
  if(!loading){

    if(mainImageS3&&pName&&subCategory!==0&&pPrice&&pQuantity){
      var valid=true
      properties.forEach(p => {
        
        if(p.data.length==0)
        {
          valid=false
        }
      });
      if(valid){

      
      setLoading(true)
      var images=[]
      ImagesS3.forEach(i => {
        images.push(i.uri)
      });
    
    
      const response=await Apis.uploadProduct({
        mainImage:mainImageS3.uri,
        images:images,
        name:pName,
        category:subCategory,
        price:pPrice,
        discount:pDiscount,
        properties:properties,
        quantity:pQuantity
      })
      //alert(JSON.stringify(response))
      if(response.status=="success"){
        setLoading(false)
        Toast.show({
          type: 'success',
          text1:response.msg
        })    

        navigation.goBack()
          }
      else{
        setLoading(false)
    
        Toast.show({
          type: 'error',
          text1:response.msg
        })  
          }
        }
        else{
          Toast.show({
            type: 'error',
            text1:"جميع الخصائص يجب أن تحتوي على قيمة واحدة على الأقل"
          })
        }
    }
    else{
      var errorData=""

      if(!mainImageS3){
        errorData+="\n• "+"الصورة الرئيسية"

      }
      if(!pName){
        errorData+="\n• "+"الاسم"

      }
      if(subCategory==0){
        errorData+="\n• "+"التصنيف الفرعي"

      }
      if(!pPrice){
        errorData+="\n• "+"السعر"

      }
      if(!pQuantity){
        errorData+="\n• "+"الكمية المتوفرة"

      }
      Toast.show({
        type: 'error',
        text1:"هذه الحقول مطلوبة"+errorData
      })
    }

}
}
useEffect(()=>{

  getCategories()
},[])
useEffect(()=>{
  getSubCategories(category)
},[category])

useEffect(()=>{
if(firstImage){


  const formData = new FormData();
  formData.append('image', {
    uri: firstImage.uri,
    type: 'image/jpeg',
    name: 'image.jpg',
  });
  
  fetch('https://api.imagga.com/v2/colors', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Basic YWNjXzQ5MGVhMjE5YjQ2MzFlMzo4MGNmYWEwYTI3MmFhZjUxMDRmNWZkYzIyZTg1N2ZhOQ=='
    },
    body: formData,
  })
  .then((response) => response.json())
  .then((responseJson) => {
  //  console.log(responseJson);
    if(responseJson.status.type=="success"){
      var colors=[]
      responseJson.result.colors.image_colors.forEach(c => {
        colors.push(c.html_code)
      });
      responseJson.result.colors.foreground_colors.forEach(c => {
        colors.push(c.html_code)
      });
      responseJson.result.colors.background_colors.forEach(c => {
        colors.push(c.html_code)
      });
      const uniqueArray = [...new Set(colors)];


      setColorsFromImage([...uniqueArray])
    }

  })
  .catch((error) => {
    console.error(error);
  });
}

},[mainImageS3])
  return (
    <>
 {
 /*       <ActionSheet
 destructiveButtonIndex={0}
 visible={isMainImageFilterShown}
 containerStyle={{marginBottom:50}}
 onDismiss={()=>{
    setIsMainImageFilterShown(false)
 }}
 renderAction={(option)=>
        <TouchableOpacity
        onPress={()=>{
          
          
          if(option.value==1){
            handleFirstImageCamera()

          }
          else if(option.value==2){
            handleFirstImage()

          }
        
          setIsMainImageFilterShown(false)

        }}
        >
        <Text style={styles.option}>{option.label}</Text>
        <View style={{ borderBottomWidth: 0.5,width:"90%",marginLeft:"auto",marginRight:"auto",borderColor:"gray" }} />

        </TouchableOpacity>
    
 }
 options={[
  {label: 'من الكاميرا',value:1},
  {label: 'من الاستوديو',value:2},
 ]}
/>
*/
}
{
      confirmationModalVisible&& <ConfirmationModal onpress={onModalPress} msg={confirmationModalMessage} setVisible={setConfirmationModalVisible}/>
    }
     {
      addImageModalVisible&& <AddImageModal onpressCamera={onAddImageModalPressCamera} onpressStudio={onAddImageModalPressStudio} setVisible={setAddImageModalVisible}/>
    }
 <Modal
        visible={isaddCategoryModalVisible}
        transparent={true}
        animationType="slide"
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>إضافة تصنيف</Text>
            <TextInput
              style={styles.input}
              placeholder="اسم التصنيف"
              value={AddCatName}
              onChangeText={(text) => setAddCatName(text)}
              textAlign="right"
            />
          <TouchableOpacity
                onPress={()=>{
                /*  setOnAddImageModalPressStudio(()=>()=>{
                    handleFirstImage()

                  })
                  setOnAddImageModalPressCamera(()=>()=>{
                    handleFirstImageCamera()

                  })
                  setAddImageModalVisible(true)
              */
                  handleAddCategoryImage()
                }}
                >
                  <View
                    style={{
                      width: 150,
                      aspectRatio: 2 / 3,
                      alignItems: "center",
                      borderWidth: addCategoryImage?0:0.5,
                      borderRadius: 10,
                      marginHorizontal: 10,
                      marginVertical:20,
                      shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 4,
},
shadowOpacity: 0.30,
shadowRadius: 4.65,
                    }}
                  >
                    {
                      addCategoryImage?
                      <> 
                       <Image
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      borderRadius: 10,

                    }}
                    source={{uri:addCategoryImage.uri}}
                  />
                    {
                !(addCategoryImagePreview?.id===addCategoryImage?.id)&&(
                   <View 
              style={{position:"absolute",alignContent:"center",alignItems:"center",justifyContent:"center",width:"100%",height:"100%",backgroundColor:"#ffffff99"}}
              >
              <ActivityIndicator size="large" color="#E63946dd"/>
              
              </View>
                )
              }
                      </>
                  
                  
                      :
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        source={require("./assets/plus.png")}
                      />
                    }
                  </View>
                </TouchableOpacity>
            <View style={{
                flexDirection:"row-reverse",
                marginLeft:"auto",
                marginRight:"auto"
            }}>
<TouchableOpacity
              style={styles.addButton}
              onPress={handleAddCategory}
            >
              <Text style={styles.addButtonText}>إضافة</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancelButton}
              onPress={() => {
                setAddCategoryImage(null)
                setAddCategoryImagePreview(null)
                setAddCatName("")
                setAddCategoryModalVisible(false)
              
              }}
            >
              <Text style={styles.cancelButtonText}>إلغاء</Text>
            </TouchableOpacity>
            </View>
            
          </View>
        </View>
      </Modal>
      <Modal animationType="slide"
        transparent={true}
        visible={modalVisible}
     >
          
                  <View
  style={{height:"100%",alignItems:"center",justifyContent:"center",backgroundColor:"#00000044"}}
  >
    <View style={{
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: "90%",
       
    }}>
       <View
          style={{
            
            backgroundColor: "white",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            
          }}
        >
          <Text
    style={{fontFamily:"Cairo_400Regular",textAlign:"center"}}
    >
      قم بادخال اسم الخاصية للمنتج مثل ( اللون , القياس , النمرة ...الخ ) واختر نوع الخاصية ( لون أو خيارات )
        
        </Text>
      <TextInput
      label="اسم الخاصية"
      placeholder="اسم الخاصية"
      onChangeText={text => setPropertyName(text)}

  value={propertyName}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right",marginTop:20}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  outlineStyle={{borderRadius:10}}
  />
    <View
   style={{
    marginLeft:"auto",
    marginRight:"auto",
    marginTop:20
   }}
    >
    <RadioButton.Group onValueChange={newValue => setPropertyType(newValue)} value={propertyType}>
    <View
     style={{
      flexDirection:"row-reverse"
    }}
   >
      <View
       style={{
        flexDirection:"row-reverse"
      }}
      >
        <Text
        style={{
          fontFamily: "Cairo_700Bold",
          marginTop:"auto",
          marginBottom:"auto",
          marginHorizontal:20
        }}
        >لون</Text>
        <View
        style={{
          borderWidth:1,
          borderRadius:20,
          borderColor:"#E63946dd",
          backgroundColor:propertyType=="color"?"#E63946dd":"#fff"
        }}
        >
 <RadioButton value="color" 
        color="#fff"
        uncheckedColor="#E63946dd"
        />
        </View>
       
      </View>
      <View
       style={{
        flexDirection:"row-reverse"
      }}
      >
                <Text
                 style={{
                  fontFamily: "Cairo_700Bold",
                  marginTop:"auto",
                  marginBottom:"auto",
                  marginHorizontal:20
                }}
                >خيارات</Text>
                <View
        style={{
          borderWidth:1,
          borderRadius:20,
          borderColor:"#E63946dd",
          backgroundColor:propertyType=="choices"?"#E63946dd":"#fff"
        }}
        >
        <RadioButton value="choices"
                color="#fff"
                uncheckedColor="#E63946dd"

        />
        </View>
        </View>
        </View>
    </RadioButton.Group>
    
    </View>
  
</View>
<View
style={{
  flexDirection:"row"
}}
>
<TouchableOpacity
style={{
  marginHorizontal:20
}}
    onPress={()=>{

     setModalVisible(false)
    }}
    >
        <View
        style={{
            width:"100%",
            marginLeft:"auto",
            marginRight:"auto",
            backgroundColor:"#E63946dd",
            padding:10,
            borderRadius:20,
            width:100,
            marginTop:20
        }}
        >
            <Text
                style={{color:"white",fontFamily:"Cairo_700Bold",textAlign:"center"}}

            >
                إلغاء
            </Text>
        </View>
    </TouchableOpacity>
    <TouchableOpacity
style={{
  marginHorizontal:20
}}
    onPress={()=>{

      var property=properties
      property.push(
        {
          id:makeotp(5),
          name:propertyName,
          type:propertyType,
          data:[]

        }
      )

      setProperties([...property])
      setModalVisible(false)
        setPropertyName("")
    }}
    >
        <View
        style={{
            width:"100%",
            marginLeft:"auto",
            marginRight:"auto",
            backgroundColor:"#E63946dd",
            padding:10,
            borderRadius:20,
            width:100,
            marginTop:20
        }}
        >
            <Text
                style={{color:"white",fontFamily:"Cairo_700Bold",textAlign:"center"}}

            >
                إضافة
            </Text>
        </View>
    </TouchableOpacity>
    
</View>

    
</View>

</View>
</Modal>

      <View style={{ flex: 1, backgroundColor: "#fff" }}>
      {
        /*
                mainImageS3&&<Image
                source={{uri:mainImageS3.uri}}
                style={{position:"absolute", width:"100%",height:"100%",zIndex:1,elevation:1,opacity:0.3}}
              />
              */
              }
        <View
          style={{
            zIndex:2,
            backgroundColor: "#ffffff00",
            width: "90%",
            height: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            
          }}
        >
          
             <KeyboardAwareScrollView
             showsVerticalScrollIndicator={false}
             style={{marginTop: 10,marginBottom:90}}
             >
              
            
              
            
          <Text style={{ textAlign: "right",fontFamily: "Cairo_700Bold", }}>
            صور المنتج (الصورة الرئيسية هي الصورة الأولى)
          </Text>
          <View style={{ flexDirection: "row-reverse" }}>
            
            <FlatList
              inverted={true}
              data={secondImages}
              ListHeaderComponent={() => {
                
                const getItem=mainImageS3?.id===firstImage?.id

                return(
                <TouchableOpacity
                onPress={()=>{
                /*  setOnAddImageModalPressStudio(()=>()=>{
                    handleFirstImage()

                  })
                  setOnAddImageModalPressCamera(()=>()=>{
                    handleFirstImageCamera()

                  })
                  setAddImageModalVisible(true)
              */
                  handleFirstImage()
                }}
                >
                  <View
                    style={{
                      width: 150,
                      aspectRatio: 2 / 3,
                      alignItems: "center",
                      borderWidth: firstImage?0:0.5,
                      borderRadius: 10,
                      marginHorizontal: 10,
                      marginVertical:20,
                      shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 4,
},
shadowOpacity: 0.30,
shadowRadius: 4.65,
                    }}
                  >
                    {
                      firstImage?
                      <> 
                       <Image
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      borderRadius: 10,

                    }}
                    source={{uri:firstImage.uri}}
                  />
                    {
                !getItem&&(
                   <View 
              style={{position:"absolute",alignContent:"center",alignItems:"center",justifyContent:"center",width:"100%",height:"100%",backgroundColor:"#ffffff99"}}
              >
              <ActivityIndicator size="large" color="#E63946dd"/>
              
              </View>
                )
              }
                      </>
                  
                  
                      :
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        source={require("./assets/plus.png")}
                      />
                    }
                  </View>
                </TouchableOpacity>
              )}}
              ListFooterComponent={() => {
                

                if(secondImages.length<9 && firstImage)
                return(
                
                <TouchableOpacity
                onPress={()=>{
                /*  setOnAddImageModalPressStudio(()=>()=>{
                    handleSecondImages()

                  })
                  setOnAddImageModalPressCamera(()=>()=>{
                    handleSecondImagesCamera()

                  })
                  setAddImageModalVisible(true)
              */
                  handleSecondImages()
                }}
                >
                  <View
                    style={{
                      width: 150,
                      aspectRatio: 2 / 3,
                      alignItems: "center",
                      borderWidth: 0.5,
                      borderRadius: 10,
                      marginHorizontal: 10,
                      marginVertical:20,
                      shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 4,
},
shadowOpacity: 0.30,
shadowRadius: 4.65,
                    }}
                  >
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                        marginTop: "auto",
                        marginBottom: "auto",
                        
                      }}
                      source={require("./assets/plus.png")}
                    />
                  </View>
                </TouchableOpacity>
              )}}
              renderItem={({ item }) =>
              {


                const getItem=ImagesS3.find(image => image.id === item.id)
                return(
                  <TouchableOpacity
                  
                  onPress={()=>{
                   deleteImage(item.id) 
                  }}
                  >
                    <View
                style={{
                  width: 150,
                  aspectRatio: 2 / 3,
                  alignItems: "center",
                  marginHorizontal: 10,
                  marginVertical:20,
                  shadowColor: "#000",
  shadowOffset: {
  width: 0,
  height: 4,
  },
  shadowOpacity: 0.30,
  shadowRadius: 4.65,
                }}
              >
                  <Image
                style={{
                  width: "100%",
                  height: "100%",
                  marginTop: "auto",
                  marginBottom: "auto",
                  borderRadius: 10,
  
                }}
                source={{uri:item.uri}}
              />
              {
                !getItem&&(
                   <View 
              style={{position:"absolute",alignContent:"center",alignItems:"center",justifyContent:"center",width:"100%",height:"100%",backgroundColor:"#ffffff99"}}
              >
              <ActivityIndicator size="large" color="#E63946dd"/>
              
              </View>
                )
              }
             
               </View>
                  </TouchableOpacity>
                
                )
              }
              }
              horizontal
              showsHorizontalScrollIndicator={false}
              
            ></FlatList>


          </View>
          <TextInput
      label="اسم المنتج"
      placeholder="اسم المنتج"
      onChangeText={text => setPName(text)}

  value={pName}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right"}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  outlineStyle={{borderRadius:10}}
  />
<SelectList 
        setSelected={(val) => {
          setCategory(val)}} 
        data={categories} 
        save="key"
        boxStyles={{flexDirection:"row-reverse",marginTop:10,backgroundColor:"#fff"}}
        disabledItemStyles={{flexDirection:"row-reverse"}}
        dropdownItemStyles={{flexDirection:"row-reverse"}}
        inputStyles={{textAlign:"right",paddingHorizontal:5}}
        searchPlaceholder={"التصنيف"}
        fontFamily={"Cairo_400Regular"}
        dropdownStyles={{backgroundColor:"#fff"}}
        defaultOption={    {key:'0', value:'التصنيف', disabled:true}      }
    />
  
      <SelectList 
        setSelected={(val) => {
          setSubCategory(val)}} 
        data={subCategories} 
        save="key"
        boxStyles={{flexDirection:"row-reverse",marginTop:10,backgroundColor:"#fff"}}
        disabledItemStyles={{flexDirection:"row-reverse"}}
        dropdownItemStyles={{flexDirection:"row-reverse"}}
        inputStyles={{textAlign:"right",paddingHorizontal:5}}
        searchPlaceholder={"التصنيف الفرعي"}
        fontFamily={"Cairo_400Regular"}
        dropdownStyles={{backgroundColor:"#fff"}}

        defaultOption={    {key:'0', value:'التصنيف الفرعي', disabled:true}      }
    />
            <TextInput
      label="سعر المنتج"
      placeholder="سعر المنتج"
      onChangeText={text => setPPrice(text)}
keyboardType={"numeric"}
  value={pPrice}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right",marginTop:10}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  outlineStyle={{borderRadius:10}}
  />
     
          <TextInput
      label="السعر المحذوف"
      placeholder="السعر المحذوف"
      onChangeText={text => setPDiscount(text)}
keyboardType={"numeric"}
  value={pDiscount}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right",marginTop:10}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  outlineStyle={{borderRadius:10}}
  />
   <TextInput
      label="الكمية المتوفرة"
      placeholder="الكمية المتوفرة"
      onChangeText={text => setPQuantity(text)}
keyboardType={"numeric"}
  value={pQuantity}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right",marginTop:10}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  outlineStyle={{borderRadius:10}}
  />
<View
style={{flexDirection:"row-reverse"}}
>
  
<Text style={{textAlign:"right",marginTop:20,fontFamily: "Cairo_700Bold",fontSize:18}}>
    خصائص المنتج
  </Text>
  <TouchableOpacity
  onPress={()=>{
    setModalVisible(true)
  }}
  >
      <Image
                        style={{
                          width: 20,
                          height: 20,
                          marginTop: "auto",
                          marginRight:10
                        }}
                        source={require("./assets/plus.png")}
                      />
  </TouchableOpacity>

</View>
<Text
    style={{fontFamily:"Cairo_400Regular",textAlign:"right"}}
    >
    
قم بالضغط على اشارة + لاضافة الخصائص لهذا المنتج مثل ( اللون , القياس , النمرة ...الخ)       
        </Text>
{
  properties.map(item=>{

    if(item.type=="color")
    return(
      <>
       <View
style={{
  borderWidth:0.5,
  borderRadius:15,
  padding:20,
  margin:10,
  backgroundColor:"#ffffff",
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.30,
  shadowRadius: 4.65,
}}
>
<Text style={{textAlign:"right",fontFamily: "Cairo_700Bold",fontSize:18}}>
   {item.name}
  </Text>
<ColorsSelect setProperties={updateProperties} id={item.id} colorsFromImage={colorsFromImage}/>
<TouchableOpacity
    onPress={()=>{
      deleteProperty(item.id)
    }}
    >
        <View
        style={{
            width:"100%",
            marginLeft:"auto",
            marginRight:"auto",
            backgroundColor:"#E63946dd",
            padding:5,
            borderRadius:20,
            width:80,
            marginTop:10
        }}
        >
            <Text
                style={{color:"white",fontFamily:"Cairo_700Bold",textAlign:"center"}}

            >
                حذف
            </Text>
        </View>
    </TouchableOpacity>
</View>
      </>
    )
    else if(item.type=="choices"){
      return(
        <>
         <View
  style={{
    borderWidth:0.5,
    borderRadius:15,
    padding:20,
    margin:10,
    backgroundColor:"#ffffff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.30,
    shadowRadius: 4.65,
  }}
  >
  <Text style={{textAlign:"right",fontFamily: "Cairo_700Bold",fontSize:18}}>
     {item.name}
    </Text>
  <MTC setProperties={updateProperties} id={item.id}/>
  <TouchableOpacity
      onPress={()=>{
        deleteProperty(item.id)
      }}
      >
          <View
          style={{
              width:"100%",
              marginLeft:"auto",
              marginRight:"auto",
              backgroundColor:"#E63946dd",
              padding:5,
              borderRadius:20,
              width:80,
              marginTop:10
          }}
          >
              <Text
                  style={{color:"white",fontFamily:"Cairo_700Bold",textAlign:"center"}}
  
              >
                  حذف
              </Text>
          </View>
      </TouchableOpacity>
  </View>
        </>
      )
    }
  })
}
 
    </KeyboardAwareScrollView>
    
        </View>
        <View
    style={{
      position:"absolute",
      bottom:0,
      width:"100%",
      height:90,
      borderTopWidth:0.5,
      zIndex:2,
      elevation:2

    }}
    >
      <View
      style={{
width:"100%",
height:"100%",
paddingBottom:20,
backgroundColor:"#fff"

      }}
      >
  <TouchableOpacity
  disabled={loading}
      onPress={async()=>{

      /*  await AsyncStorage.setItem("token","")
        navigation.replace("landing")*/
        uploadProduct()
      }}
      >
          <View
          style={{
              width:"90%",
              marginLeft:"auto",
              marginRight:"auto",
              backgroundColor:"#E63946",
              padding:10,
              borderRadius:10,
              marginTop:10,
              height:60,
              justifyContent:"center",
          }}
          >
                   {
  loading?
  <ActivityIndicator size="small" color="#fff" />
  :    <Text
  style={{color:"white",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:20}}

>
  رفع المنتج
</Text>
}
              
          </View>
      </TouchableOpacity>
      </View>


    </View>
      </View>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
  },
  head: {
    backgroundColor: '#f1f8ff',
    flexDirection: 'row-reverse', // Right-to-Left
  },
  headText: {
    margin: 6,
    textAlign: 'center',
    fontFamily: "Cairo_400Regular", // Add your preferred font
    fontWeight: 'bold',
    fontSize: 14,
  },
  rowText: {
    margin: 6,
    textAlign: 'center',
    fontFamily: "Cairo_400Regular", // Add your preferred font
    fontSize: 14,
  },
  deleteButton: {
    backgroundColor: '#E63946dd',
    padding: 8,
    borderRadius: 5,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 120,
    marginVertical: 10
  },
  deleteButtonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: "Cairo_400Regular", // Add your preferred font
    fontSize: 14,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    width: '80%',
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
    fontFamily: 'Cairo_400Regular',

  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
    fontFamily: 'Cairo_400Regular',

  },
  addButton: {
    backgroundColor: '#4CAF50',
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
    width:"95%",
    marginRight:"auto"
  },
  addButtonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Cairo_400Regular',
    fontSize: 16,
    marginHorizontal:10
  },
  cancelButton: {
    backgroundColor: '#e74c3c',
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
    alignSelf: 'flex-end',
    marginHorizontal:10

  },
  cancelButtonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Cairo_400Regular',
    fontSize: 16,
  },
});
export default ImageInputs;
/*
  <View style={{flex:1,justifyContent:"center",alignContent:"center"}}>
      <Button title="Choose first image" onPress={handleFirstImage} />
      <Button title="Choose first image Camera" onPress={handleFirstImageCamera} />
      {firstImage && <Image source={{ uri: firstImage.uri }} style={{ width: 50, aspectRatio: 2/3 }} />}
      <Button title="Choose second images" onPress={handleSecondImages} />
      <Button title="Choose second images Camera" onPress={handleSecondImagesCamera} />
      {secondImages.map((imageUri, index) => 
        
        <Image key={index} source={{ uri: imageUri.uri }} style={{ width: 50,aspectRatio: 2/3}} />
      
      )}
      <Text>
        {mainImageS3&&
        mainImageS3.uri}
      </Text>
      {
        ImagesS3.length>0&&
        ImagesS3.map(item=>
          <Text>
            {item.uri}
          </Text>
          )
      }
    </View>

*/
