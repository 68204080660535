import React ,{useState} from 'react';
import { View, Text, Image, StyleSheet, ScrollView,TouchableOpacity,Animated } from 'react-native';
import { Table, Row } from 'react-native-table-component';
import { OverflowMenuWithDisabledItemsShowcase } from './OrderActions';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Ionicons } from "@expo/vector-icons";

const OrderTable = ({ orders,getPreviousOrders }) => {
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [detailHeight] = useState(new Animated.Value(0));

  const animateDetail = (visibility) => {
    Animated.timing(detailHeight, {
      toValue: visibility ? 100 : 0, // 200 is an example value, use the appropriate height for your detail component
      duration: 500,
    }).start();
    setIsDetailVisible(visibility)
  };
    const exportToExcel = () => {
        try {
          const wb = XLSX.utils.book_new();
      
          orders.forEach((order, index) => {
            const wsData = [
              ['الإجمالي', 'الشحن', 'المجموع', 'عدد المنتجات', 'تاريخ الطلب', 'رقم الطلب'].reverse(),
              [
                "₪ " + order.total.toString(),
                "₪ " + order.shipping.toString(),
                "₪ " + order.subtotal.toString(),
                order.cart.length.toString(),
                order.orderDate,
                order.orderId.toString(),
              ].reverse(),
              ['المنتجات'],
              ['رقم المنتج', 'اسم المنتج', 'الكمية', 'السعر'],
              ...order.cart.map(item => [item.productId, item.title, item.quantity, item.price]),
              ['تفاصيل العميل'],
              ['الاسم', 'العنوان', 'الجوال'],
              [order.name, order.address, order.mobile],
            ];
      
            const ws = XLSX.utils.aoa_to_sheet(wsData);
      
            // Set column widths
            ws['!cols'] = [
              { width: 15 }, // رقم المنتج
              { width: 30 }, // اسم المنتج
              { width: 15 }, // الكمية
              { width: 20 }, // السعر
            ];
      
            // Set row heights
            ws['!rows'] = [
              { hpx: 30 }, // Row height for the header
              { hpx: 25 }, // Row height for the values
              { hpx: 30 }, // Row height for the products header
              // Add more row heights as needed
            ];
      
            XLSX.utils.book_append_sheet(wb, ws, `Order_${order.orderId.toString()}`);
          });
      
          const excelBuffer = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
            mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
          saveAs(blob, 'orders.xlsx');
        } catch (error) {
          console.error('Error exporting to Excel:', error);
        }
      };
      
  const renderProductDetails = (cart) => {
    return (
      <Table>
        <Row
          data={['تفاصيل المنتج', 'الإجراءات','صورة', 'السعر', 'الكمية', 'المنتج']}
          style={styles.head}
          textStyle={[styles.text, { textAlign: 'right' }]}
        />
        {cart.map((item, index) => (
          <Row
            key={index}
            data={[
              <View>
                  
        <View
          
          >
         {
       
       item.productDetails.map(detail=>{
             if(detail.type=="color"){
               return(<>
                    {
           
           
           <View style={{flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}>
   
        
       <View style={{width:14,height:14,backgroundColor:detail.value,borderRadius:7,marginTop:"auto",marginBottom:"auto",marginRight:10}}>
       
       </View>
       
       
           
       <Text style={styles.detailsData}>
                      {detail.name}:
                   </Text>
           </View>
             
       
       }
               </>
             
               )
             }
             else{
   
               return(
                 <>
                   {
   
                                   <Text style={styles.detailsData}>{detail.name}: {detail.value}</Text>
   
                  }
                 </>
               )
             }
           })
   
   
       }
          
           </View>
              </View>
             ,
                <View
                style={{
                    marginLeft:"auto"
                }}
                >
                     <OverflowMenuWithDisabledItemsShowcase key={index} product={item} onchange={() => {

                    getPreviousOrders()
    
                  }} />
                
                </View>
               ,
              <Image key={index} source={{ uri: item.image.uri }} style={styles.image} />,
             
              item.price,
               item.quantity.toString(),
              item.title,
            
            ]}
            style={styles.row}
            textStyle={[styles.text, { textAlign: 'right' }]}
          />
        ))}
      </Table>
    );
  };

  return (
              
<ScrollView style={styles.container}>
<TouchableOpacity style={{width:130,height:60,justifyContent:"center",marginBottom:20}}
        onPress={()=>{
            exportToExcel()
          
        }}
        >

        <View style={styles.buynowContainer}>
        {
   <Text style={styles.checkcouponbutton}>

  تصدير ملف Excel    
        </Text>
}
</View>
</TouchableOpacity>
                   {orders.map((order) => (
                     <View key={order.orderId} style={styles.card}>
                                   <Text style={styles.heading}>{ order.orderId.toString()}#</Text>
             
                       <Table borderStyle={{ borderColor: '#C1C0B9' }}>
                         <Row
                           data={[ 'الإجمالي','الشحن','المجموع','عدد المنتجات', 'تاريخ الطلب', 'رقم الطلب']}
                           style={styles.head}
                           textStyle={[styles.text, { textAlign: 'right' }]}
                         />
                         <Row
                           data={[
                             "₪ "+order.total.toString(),
                             "₪ "+order.shipping.toString(),
                             "₪ "+ order.subtotal.toString(),
                             order.cart.length.toString(),
                             order.orderDate,
                             order.orderId.toString(),
                           ]}
                           style={styles.row}
                           textStyle={[styles.text, { textAlign: 'right' }]}
                         />
                       </Table>
             
                       <Text style={styles.heading}>المنتجات</Text>
                       {renderProductDetails(order.cart)}
             
                       <Text style={styles.heading}>تفاصيل العميل</Text>
                       <Table borderStyle={{ borderColor: '#C1C0B9' }}>
                         <Row
                           data={['العنوان', 'الجوال', 'الاسم']}
                           style={styles.head}
                           textStyle={[styles.text, { textAlign: 'right' }]}
                         />
                         <Row
                           data={[order.address, order.mobile, order.name]}
                           style={styles.row}
                           textStyle={[styles.text, { textAlign: 'right' }]}
                         />
                       </Table>
                     </View>
                   ))}
                 </ScrollView>
   
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, padding: 16, paddingTop: 30, backgroundColor: '#fff' },
  card: { marginBottom: 20, borderWidth: 1, borderColor: '#ddd', borderRadius: 8, padding: 10 },
  head: { fontFamily: "Cairo_400Regular",height: 40, backgroundColor: '#f1f8ff' },
  text: { fontFamily: "Cairo_400Regular",margin: 6, textAlign: 'center' },
  row: { fontFamily: "Cairo_400Regular",flexDirection: 'row', backgroundColor: '#E6394633' },
  heading: {fontFamily: "Cairo_400Regular", fontSize: 18, fontWeight: 'bold', marginTop: 10,textAlign:"right"},
  image: { width: 70, height: 70,marginVertical:20,marginLeft:"auto" },
  buynowContainer:{
    backgroundColor:"#E63946dd",
    height:60,
    borderRadius:10,
    justifyContent:"center",
    alignItems:"center"
  },
  checkoutbuttoncontainer:{
    width:"100%",
    backgroundColor:"#E6394633",
    height:60,
    borderRadius:10,
    justifyContent:"center",
    alignItems:"center",
    marginHorizontal:10
  },
  checkcouponbutton:{
    fontFamily: "Cairo_700Bold",
    color:"white",
   textAlign:"center"
    },
    detailsData:{
      textAlign:"right",
      fontFamily: "Cairo_400Regular",
  
    }
});

export default OrderTable;
