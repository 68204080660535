import React,{useState,useEffect} from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { FontAwesome, MaterialIcons  } from '@expo/vector-icons'; 
import { StyleSheet } from "react-native";
import { Ionicons,Feather } from "@expo/vector-icons";
import HomeTab from "./HomeTab";
import Products from "./Products";
import Orders from "./Orders";
import Payments from "./Payments";
import Account from "./Account";
import Categories from "./Categories";
import SendNotification from "./SendNotification";
import OrdersAll from "./OrdersAll";
import CreateAd from "./CreateAd";
import Ads from "./Ads";
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import CouponTable from "./Coupons";
import Shipping from "./Shipping";
const Tab = createBottomTabNavigator();

const TabNavigation = ({ navigation }) => {

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarActiveTintColor: "#E63946dd",
        tabBarInactiveTintColor: "gray",
      }}
    >
      <Tab.Screen
        name="HomeTab"
        component={HomeTab}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="ios-home-outline" color={color} size={size} />
          ),
          tabBarLabel: "الرئيسية",
          tabBarLabelStyle: { fontFamily: "Cairo_800ExtraBold" },
        }}
      />
      <Tab.Screen
        name="Products"
        component={Products}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
            <Feather name="box" color={color} size={size} />
            ),
          tabBarLabel: "المنتجات",
          tabBarLabelStyle: { fontFamily: "Cairo_800ExtraBold" },
        }}
      />
       <Tab.Screen
        name="Orders"
        component={OrdersAll}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
<Ionicons name="cart-outline" size={size} color={color} />       
     ),
          tabBarLabel: "الطلبيات",
          tabBarLabelStyle: { fontFamily: "Cairo_800ExtraBold" },
        }}
      />
        <Tab.Screen
        name="Coupons"
        component={CouponTable}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
<MaterialCommunityIcons name="ticket-percent-outline" size={size} color={color} />
),
          tabBarLabel: "الكوبونات",
          tabBarLabelStyle: { fontFamily: "Cairo_800ExtraBold" },
        }}
      />
          <Tab.Screen
        name="Shipping"
        component={Shipping}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
<MaterialCommunityIcons name="truck-fast-outline" size={size} color={color} />),
          tabBarLabel: "التوصيل",
          tabBarLabelStyle: { fontFamily: "Cairo_800ExtraBold" },
        }}
      />
          <Tab.Screen
        name="Categories"
        component={Categories}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
<MaterialIcons name="category" size={size} color={color} />     ),
          tabBarLabel: "التصنيفات",
          tabBarLabelStyle: { fontFamily: "Cairo_800ExtraBold" },
        }}
      />
      

<Tab.Screen
        name="Notifications"
        component={SendNotification}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
<Ionicons name="ios-notifications-outline" size={size} color={color} />   
  ),
          tabBarLabel: "الاشعارات",
          tabBarLabelStyle: { fontFamily: "Cairo_800ExtraBold" },
        }}
      />
      <Tab.Screen
        name="CreateAd"
        component={Ads}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
<MaterialCommunityIcons name="advertisements" size={size} color={color} />  ),
          tabBarLabel: "الاعلانات",
          tabBarLabelStyle: { fontFamily: "Cairo_800ExtraBold" },
        }}
      />
          <Tab.Screen
        name="Account"
        component={Account}
        options={{
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
<Feather name="user" size={size} color={color} />       
     ),
          tabBarLabel: "الحساب",
          tabBarLabelStyle: { fontFamily: "Cairo_800ExtraBold" },
        }}
      />
    </Tab.Navigator>
  );
};

export default TabNavigation;
