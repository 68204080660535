import React from 'react';
import { View, FlatList  } from 'react-native';
import ProductCard from './ProductCard';
function ProductsCardsSquare({products,navigation}) {
  return (
    <View>
          <FlatList
      data={products}
      renderItem={({ item }) => <ProductCard navigation={navigation} product={item}/>}
      keyExtractor={item => item.id}
      numColumns={1}
    />
    
     
    
    </View>
  );
}

const styles = {
  row: {
    flexDirection: 'row',
  },
};

export default ProductsCardsSquare;
