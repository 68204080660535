import React from 'react';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import { Button, Overlay } from 'react-native-elements';
import Apis from './Apis';
import { Toast } from 'react-native-toast-message/lib/src/Toast';

export const OverflowMenuWithDisabledItemsShowcase = ({ product, onchange }) => {
  //console.log("Menu", product);
  const [visible, setVisible] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(product.adminstatus + 1);

  const onItemSelect = async (index) => {
    setSelectedIndex(index);
    setVisible(false);

    const response = await Apis.updateCartStatusStore(product.cartId, index - 1);
    if (response.status === "success") {
      onchange("success", response.msg);
    } else {
      onchange("error", response.msg);
    }
  };



  return (
    <>
         <TouchableOpacity
      onPress={() => {
        setVisible(true);
      }}
      style={{
        marginTop: "auto",
        marginBottom: "auto"
      }}
    >
      <View style={styles.quantityContainer}>
        <View
          style={{
            backgroundColor:
              product.adminstatus === 0
                ? "#2f6690dd"
                : product.adminstatus === 1
                ? "#ffba08dd"
                : product.adminstatus === 2
                ? "#4daa57dd"
                : product.adminstatus === 3
                ? "#f95738dd"
                : product.adminstatus === 4
                ? "#e63946dd"
                : product.adminstatus === 5
                ? "#e63946dd"
                : product.adminstatus === 6
                ? "#4daa57dd"
                : "#e63946dd",
            padding: 4,
            paddingHorizontal: 8,
            borderRadius: 10,
            minWidth: 80
          }}
        >
          <Text style={[styles.title, { color: "#fff", fontSize: 11, textAlign: "center" }]}>
            {product.adminstatus === 0
              ? "جديد"
              : product.adminstatus === 1
              ? "جاهز للشحن"
              : product.adminstatus === 2
              ? "في الشحن"
              : product.adminstatus === 3
              ? "الكمية منتهية"
              : product.adminstatus === 4
              ? "ملغي"
              : product.adminstatus === 5
              ? "مرتجع"
              : product.adminstatus === 6
              ? "تم التسليم"
              : "خطأ"}
          </Text>
        </View>
        <Text style={{ textAlign: "center", fontFamily: "Cairo_400Regular", fontSize: 12 }}>
          تغيير الحالة
        </Text>
      </View>
    </TouchableOpacity>  

      {
        <Overlay isVisible={visible} onBackdropPress={() => setVisible(false)}>
          <View>
            <TouchableOpacity onPress={() => onItemSelect(1)}>
              <Text style={styles.menuItem}>جديد</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onItemSelect(2)}>
              <Text style={styles.menuItem}>جاهز للشحن</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onItemSelect(3)}>
              <Text style={styles.menuItem}>في الشحن</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onItemSelect(4)}>
              <Text style={styles.menuItem}>الكمية منتهية</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onItemSelect(5)}>
              <Text style={styles.menuItem}>ملغي</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onItemSelect(6)}>
              <Text style={styles.menuItem}>مرتجع</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onItemSelect(7)}>
              <Text style={styles.menuItem}>تم التسليم</Text>
            </TouchableOpacity>
          </View>
        </Overlay>
      }
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    textAlign: "right",
    fontFamily: "Cairo_700Bold"
  },
  quantityContainer: {
    marginLeft: 15,
    alignItems: 'center',
    marginTop: "auto",
    marginBottom: "auto"
  },
  menuItem: {
    fontFamily: "Cairo_700Bold",
    width: "100%",
    textAlign: "right",
    fontSize: 16
  }
});
