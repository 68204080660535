
import React,{useState,useCallback,useEffect} from "react"
import {View, ScrollView ,StyleSheet,RefreshControl,TouchableOpacity,TextInput  } from "react-native"
import { Text, SkeletonView,ActionSheet } from "react-native-ui-lib";
import { FontAwesome } from "@expo/vector-icons";
import Apis from "./Apis";
import PaymentCard from "./PaymentCard";
 const Payments=({navigation})=>{
    const [isPaymentsAvailable,setIsPaymentsAvailable]=useState(true)
    const [refreshing, setRefreshing] = useState(false);
    const [payments,setPayments]=useState([])
    const [page,setPage]=useState(1)
    const [total,setTotal]=useState(0)

    const getPayments=async()=>{
     const response=await Apis.getPayments();


      if(response.status=="success"){
        setPage(1)

      
        var sum=0;
        response.data.forEach(p => {
        sum+=p.amount
        });
        setTotal(sum)

        setPayments([...response.data])
        setIsPaymentsAvailable(true)

      }
    }


    useEffect(() => {
        getPayments();

    }, [])
    const onRefresh = useCallback(() => {
        setRefreshing(true);
        setPage(1)
        setIsPaymentsAvailable(false)
        getPayments();


        setRefreshing(false)
        
      }, []);


      const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
        const paddingToBottom = 150;
        return layoutMeasurement.height + contentOffset.y >=
          contentSize.height - paddingToBottom;
      };
         
     
    return(
        <>
 
   
         <View style={styles.container}>
      <View style={styles.Innercontainer}>
      <View style={{padding:10,backgroundColor:"#4daa57dd",borderRadius:16,flexDirection:"row",marginBottom:10}}>
            <FontAwesome name="money" color={"#ffffffdd"} size={50} style={{marginTop:"auto",marginBottom:"auto",marginLeft:30}}/>
  
      <View style={{marginLeft:"auto"}}>
      <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:35,color:"#ffffffdd"}}>
  {total} ₪
  </Text>
  <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:18,color:"#ffffffdd"}}>
 الرصيد
  </Text>
  
      </View>
  
  
  </View>
        <ScrollView
        showsVerticalScrollIndicator={false}
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
           
           setPage(page+1)
          }
        }}
       
        scrollEventThrottle={400}
        refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={"#E63946dd"}  colors={["#E63946dd"]}/>
          }
        >
           
        {
                isPaymentsAvailable?(<>
                

                {
                    payments.map((item,index)=>{

                        if(index<page*10)
                        return(<>
                                        <PaymentCard payment={{
    amount:item.amount,
    text:item.title,
    dateadded:item.dateadded,
    id:item.id,
    details:item.details                        
}}
/>
                        </>)
                    })
                }

                </>)
                :<SkeletonView
              template={SkeletonView.templates.LIST_ITEM}
              times={10}
              circle={true}
            
            />
            }
        </ScrollView>
        </View>
        </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
      backgroundColor: "white",
      width: "100%",
      height: "100%",
    },
    Innercontainer: {
      backgroundColor: "white",
      width: "90%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop:70
    },
    searchInput: {
      width: "100%",
      backgroundColor: "#0000000d",
      marginLeft: "auto",
      marginRight: "auto",
      padding: 13,
      borderRadius: 10,
      fontFamily: "Cairo_400Regular",
      textAlign:"right"
    },
    searchInputText: {
      textAlign: "right",
      color: "#00000080",
      fontFamily: "Cairo_400Regular",
    },
    searchInputTextBlack: {
      textAlign: "right",
      color: "black",
      fontFamily: "Cairo_700Bold",
    },
    option:{
        textAlign:"right",
        paddingHorizontal:20,
        paddingVertical:15,
        fontFamily: "Cairo_700Bold",
        fontSize:14
    }
  });
  
export default Payments