import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, TextInput, Image,ActivityIndicator ,ScrollView} from 'react-native';
import { Table, Row } from 'react-native-table-component';
import Apis from './Apis';
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import * as ImageManipulator from 'expo-image-manipulator';

const SubCategoriesTable = ({navigation,route}) => {
  const [categories, setcategories] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [categoryName, setcategoryName] = useState('');
  const [categoryImage, setcategoryImage] = useState(null);
  const [categoryImagePreview, setcategoryImagePreview] = useState(null);
  const {id}=route.params;

  function makeotp(length) {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleAddCategoryImage = async () => {
    const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
   // console.log(status)

    //if (status === "granted") 
    {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false,
        aspect: [3, 4],
        base64: true,
      });
      if (!result.canceled) {
        var id = makeotp(5);
        const compressedImage = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          [{ resize:{
          
            width:500
          } }],
          {base64:true, format: 'jpeg' }
        );
        setcategoryImage({ id: id, uri: compressedImage.uri });
        //uploadToS3(id, "main", compressedImage.base64);
        uploadToServer(id, "addcategory", compressedImage.uri);

      }
      else
      {
       // console.log(result)
      }
    }
  };
  useEffect(() => {
    // Fetch categories data from API when the component mounts
    fetchcategories();
  }, []);
  const uploadToServer = async (id, type, base64) => {
 
    try {
      const responseupload = await Apis.uploadToServer({
        base64:base64
      })
//console.log(responseupload)
  if (responseupload.data.status === "success") {

     if(type=="addcategory"){
      setcategoryImagePreview({ id, uri: responseupload.location });

    }
  }
    
    } catch (error) {
      console.error("Error uploading to server:", error);
    }
  };
  const fetchcategories = async () => {
    try {
      const categoriesData = await Apis.getSubCategories(id);
      setcategories(categoriesData.data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleDeletecategory = async (id) => {
    try {
      await Apis.deleteSubCategory(id);
      // Update the categories list after deletion
      fetchcategories();
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleAddcategory = async () => {
    try {
      await Apis.addSubCategory(categoryName, categoryImagePreview.uri,id);
      // Update the categories list after adding
      fetchcategories();
      // Close the modal
      setModalVisible(false);
      // Reset input values
      setcategoryName('');
      setcategoryImage(null);
      setcategoryImagePreview(null);
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const tableHead = ['#', 'الاسم', 'الصورة', ''];
  const tableData = categories.map((category,index) => [
    category.id,
    category.name,
    (
      <Image key={index} source={{ uri: category.image }} style={{
        width: 70, height: 70,marginVertical:20,marginLeft:"auto" ,marginRight:"auto"
      }} />
    )
    ,
    (
 <TouchableOpacity
        style={styles.deleteButton}
        onPress={() => handleDeletecategory(category.id)}
      >
        <Text style={styles.deleteButtonText}>حذف</Text>
      </TouchableOpacity>
    
     
    ),
  ].reverse());

 
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.addButton}
        onPress={() => setModalVisible(true)}
      >
        <Text style={styles.addButtonText}>إضافة تصنيف</Text>
      </TouchableOpacity>

<ScrollView>
      <Table borderStyle={{ borderWidth: 1, borderColor: '#C1C0B9' }}>
        <Row
          data={tableHead}
          style={styles.head}
          textStyle={styles.headText}
        />
        {tableData.map((rowData, index) => (
          <Row key={index} data={rowData} textStyle={styles.rowText} />
        ))}
      </Table>
</ScrollView>
  

      {/* Modal for adding category */}
      <Modal
        visible={isModalVisible}
        transparent={true}
        animationType="slide"
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>إضافة تصنيف</Text>
            <TextInput
              style={styles.input}
              placeholder="اسم التصنيف"
              value={categoryName}
              onChangeText={(text) => setcategoryName(text)}
              textAlign="right"
            />
          <TouchableOpacity
                onPress={()=>{
                /*  setOnAddImageModalPressStudio(()=>()=>{
                    handleFirstImage()

                  })
                  setOnAddImageModalPressCamera(()=>()=>{
                    handleFirstImageCamera()

                  })
                  setAddImageModalVisible(true)
              */
                  handleAddCategoryImage()
                }}
                >
                     <Text style={{
                       textAlign: 'center',
                       fontFamily: 'Cairo_400Regular',
                       fontSize: 16,
                     }}>
                      الصورة
                    </Text>
                  <View
                    style={{
                      width: 150,
                      aspectRatio: 2 / 3,
                      alignItems: "center",
                      borderWidth: categoryImage?0:0.5,
                      borderRadius: 10,
                     marginLeft:"auto",
                     marginRight:"auto",
                      marginVertical:20,
                      shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 4,
},
shadowOpacity: 0.30,
shadowRadius: 4.65,
                    }}
                  >
                 
                    {
                      categoryImage?
                      <> 
                       <Image
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      borderRadius: 10,

                    }}
                    source={{uri:categoryImage.uri}}
                  />
                    {
                !(categoryImagePreview?.id===categoryImage?.id)&&(
                   <View 
              style={{position:"absolute",alignContent:"center",alignItems:"center",justifyContent:"center",width:"100%",height:"100%",backgroundColor:"#ffffff99"}}
              >
              <ActivityIndicator size="large" color="#E63946dd"/>
              
              </View>
                )
              }
                      </>
                  
                  
                      :
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        source={require("./assets/plus.png")}
                      />
                    }
                  </View>
                </TouchableOpacity>
            <View style={{
                flexDirection:"row-reverse",
                marginLeft:"auto",
                marginRight:"auto"
            }}>
<TouchableOpacity
              style={styles.addButton}
              onPress={handleAddcategory}
            >
              <Text style={styles.addButtonText}>إضافة</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancelButton}
              onPress={() => {
                setcategoryImage(null)
                setcategoryImagePreview(null)
                setcategoryName("")
                setModalVisible(false)
              
              }}
            >
              <Text style={styles.cancelButtonText}>إلغاء</Text>
            </TouchableOpacity>
            </View>
            
          </View>
        </View>
      </Modal>
    </View>
  );
};

;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
  },
  head: {
    backgroundColor: '#f1f8ff',
    flexDirection: 'row-reverse', // Right-to-Left
  },
  headText: {
    margin: 6,
    textAlign: 'center',
    fontFamily: "Cairo_400Regular", // Add your preferred font
    fontWeight: 'bold',
    fontSize: 14,
  },
  rowText: {
    margin: 6,
    textAlign: 'center',
    fontFamily: "Cairo_400Regular", // Add your preferred font
    fontSize: 14,
  },
  deleteButton: {
    backgroundColor: '#E63946dd',
    padding: 8,
    borderRadius: 5,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 120,
    marginVertical: 10
  },
  subcatsButton: {
    backgroundColor: '#4CAF50',
    padding: 8,
    borderRadius: 5,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 120,
    marginVertical: 10
  },
  deleteButtonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: "Cairo_400Regular", // Add your preferred font
    fontSize: 14,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    width: '80%',
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
    fontFamily: 'Cairo_400Regular',

  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
    fontFamily: 'Cairo_400Regular',

  },
  addButton: {
    backgroundColor: '#4CAF50',
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
    alignSelf: 'flex-end',
  },
  addButtonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Cairo_400Regular',
    fontSize: 16,
    marginHorizontal:10
  },
  cancelButton: {
    backgroundColor: '#e74c3c',
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
    alignSelf: 'flex-end',
    marginHorizontal:10

  },
  cancelButtonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Cairo_400Regular',
    fontSize: 16,
  },
});

export default SubCategoriesTable;
