import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, TextInput, Button,ScrollView } from 'react-native';
import { Table, Row } from 'react-native-table-component';
import Apis from './Apis';

const Shipping = () => {
  const [Shippings, setShippings] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [ShippingArea, setShippingArea] = useState('');
  const [ShippingPrice, setShippingPrice] = useState('');

  useEffect(() => {
    // Fetch Shippings data from API when the component mounts
    fetchShippings();
  }, []);

  const fetchShippings = async () => {
    try {
      const ShippingsData = await Apis.getShipping();
      setShippings(ShippingsData.data || []);
    } catch (error) {
      console.error('Error fetching Shippings:', error);
    }
  };

  const handleDeleteShipping = async (id) => {
    try {
      await Apis.deleteShipping(id);
      // Update the Shippings list after deletion
      fetchShippings();
    } catch (error) {
      console.error('Error deleting Shipping:', error);
    }
  };

  const handleAddShipping = async () => {
    try {
      await Apis.addShipping(ShippingArea, ShippingPrice);
      // Update the Shippings list after adding
      fetchShippings();
      // Close the modal
      setModalVisible(false);
      // Reset input values
      setShippingArea('');
      setShippingPrice('');
    } catch (error) {
      console.error('Error adding Shipping:', error);
    }
  };

  const tableHead = ['#', 'المنطقة', 'السعر', 'حذف'];

  const tableData = Shippings.map((Shipping) => [
    Shipping.id,
    Shipping.area,
    Shipping.price,
    (
      <TouchableOpacity
        style={styles.deleteButton}
        onPress={() => handleDeleteShipping(Shipping.id)}
      >
        <Text style={styles.deleteButtonText}>حذف</Text>
      </TouchableOpacity>
    ),
  ].reverse());

 
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.addButton}
        onPress={() => setModalVisible(true)}
      >
        <Text style={styles.addButtonText}>إضافة منطقة</Text>
      </TouchableOpacity>

<ScrollView>

  <Table borderStyle={{ borderWidth: 1, borderColor: '#C1C0B9' }}>
        <Row
          data={tableHead}
          style={styles.head}
          textStyle={styles.headText}
        />
        {tableData.map((rowData, index) => (
          <Row key={index} data={rowData} textStyle={styles.rowText} />
        ))}
      </Table>
</ScrollView>
      

      {/* Modal for adding Shipping */}
      <Modal
        visible={isModalVisible}
        transparent={true}
        animationType="slide"
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>إضافة منطقة</Text>
            <TextInput
              style={styles.input}
              placeholder="اسم المنطقة"
              value={ShippingArea}
              onChangeText={(text) => setShippingArea(text)}
              textAlign="right"
            />
            <TextInput
              style={styles.input}
              placeholder="سعر التوصيل"
              value={ShippingPrice}
              onChangeText={(text) => setShippingPrice(text)}
              keyboardType="numeric"
              textAlign="right"
            />
            <View style={{
                flexDirection:"row-reverse",
                marginLeft:"auto",
                marginRight:"auto"
            }}>
<TouchableOpacity
              style={styles.addButton}
              onPress={handleAddShipping}
            >
              <Text style={styles.addButtonText}>إضافة</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancelButton}
              onPress={() => setModalVisible(false)}
            >
              <Text style={styles.cancelButtonText}>إلغاء</Text>
            </TouchableOpacity>
            </View>
            
          </View>
        </View>
      </Modal>
    </View>
  );
};

;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
  },
  head: {
    backgroundColor: '#f1f8ff',
    flexDirection: 'row-reverse', // Right-to-Left
  },
  headText: {
    margin: 6,
    textAlign: 'center',
    fontFamily: "Cairo_400Regular", // Add your preferred font
    fontWeight: 'bold',
    fontSize: 14,
  },
  rowText: {
    margin: 6,
    textAlign: 'center',
    fontFamily: "Cairo_400Regular", // Add your preferred font
    fontSize: 14,
  },
  deleteButton: {
    backgroundColor: '#E63946dd',
    padding: 8,
    borderRadius: 5,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 120,
    marginVertical: 10
  },
  deleteButtonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: "Cairo_400Regular", // Add your preferred font
    fontSize: 14,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    width: '80%',
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
    fontFamily: 'Cairo_400Regular',

  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
    fontFamily: 'Cairo_400Regular',

  },
  addButton: {
    backgroundColor: '#4CAF50',
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
    alignSelf: 'flex-end',
  },
  addButtonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Cairo_400Regular',
    fontSize: 16,
    marginHorizontal:10
  },
  cancelButton: {
    backgroundColor: '#e74c3c',
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
    alignSelf: 'flex-end',
    marginHorizontal:10

  },
  cancelButtonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Cairo_400Regular',
    fontSize: 16,
  },
});

export default Shipping;
