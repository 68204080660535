
import React,{useState,useEffect} from "react";
import { View,Text, TouchableOpacity,Modal, ScrollView } from "react-native";
import Tags from "react-native-tags";

const MTCEdit = ({setProperties,properties,id}) => {
    const [selectedMTCs,setSelectedMTCs]=useState([])
    const [tagsReaday,setTagsReady]=useState(false)

    useEffect(()=>{
      properties.map(obj => {
         if (obj.id === id) {
          setSelectedMTCs([...obj.data])
          setTagsReady(true)
         }
       });
       setTagsReady(true)

  },[])

      useEffect(()=>{

        setProperties(id,selectedMTCs)

      },[selectedMTCs])
    return(

        <>
               <Text
    style={{fontFamily:"Cairo_400Regular",textAlign:"center"}}
    >
    
    قم بكتابة الخيارات  واضغط "مسافة" بعد كل خيار للتثبيت
        
        </Text>
        <Text
    style={{fontFamily:"Cairo_400Regular",textAlign:"center"}}
    >
    
اضغط على الخيار لحذفه        
        </Text>
        <View
        style={{borderWidth:0.5,borderRadius:10,marginTop:10}}
        >
          {
            tagsReaday&&(
                   <Tags
        
        initialTags={selectedMTCs}
    onChangeTags={tags => setSelectedMTCs(tags)}
    
    containerStyle={{ justifyContent: "center" }}
    inputStyle={{ backgroundColor: "white",fontFamily:"Cairo_400Regular" }}
    tagTextStyle={{ fontFamily:"Cairo_400Regular" }}
   
  />
            )
          }
   
        </View>

      
        </>
    )

}

export default MTCEdit