import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from "react-native";

import { Popup } from "react-native-popup-confirm-toast";
import { Divider } from "react-native-paper"
import Moment from 'moment';

function PaymentCard(props) {
  const { payment } = props;

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity
          style={{ marginTop: "auto", marginBottom: "auto",width:"100%" }}
          onPress={() => {
            //navigation.push("ProductPage")
            Popup.show({
              iconEnabled:false,
              title: 'تفاصيل العملية',
              textBody: payment.details,
              buttonText: 'إغلاق',
              titleTextStyle:{fontFamily:"Cairo_700Bold"},
              descTextStyle:{fontFamily:"Cairo_400Regular"},
              okButtonTextStyle:{fontFamily:"Cairo_700Bold"},
              okButtonStyle:{backgroundColor: '#E63946dd'},
              callback: () => Popup.hide()
            })
          }}
        >
          <View style={{ flexDirection: "row" ,alignItems:"center"}}>
            <View
            style={{marginRight:"auto"}}
            >
              <Text
              style={{
                fontFamily: "Cairo_700Bold",
                fontSize:20,
                color:payment.amount>0?"#4daa57dd":"#E63946dd"
              }}
              >{payment.amount>0?"+":""}{payment.amount} ₪</Text>
            </View>
            <View
            style={{marginLeft:"auto",marginRight:"auto",alignItems:"center"}}
            >
              <Text
              style={{
                fontFamily: "Cairo_700Bold",
              }}
              >{payment.text}</Text>
              <Text
              style={{
                fontFamily: "Cairo_700Bold",

              }}
              >{Moment(payment.dateadded).format("DD-MM-yyyy")}</Text>
            </View>
            <View
            style={{
              marginLeft:"auto"
            }}
            >
              <Text
              style={{
                fontFamily: "Cairo_700Bold",
              }}
              >{payment.id}#</Text>
            </View>
          </View>

        </TouchableOpacity>
        
      </View>
      <Divider/>

    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 10,
    width:"100%"
  },
  textContainer: {
    flex: 1,
    paddingHorizontal: 10,
  },
  quantityContainer: {
    width: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    height: 100,
    aspectRatio: 2 / 3,
    borderRadius: 10,
  },
  title: {
    fontSize: 14,
    textAlign: "right",
    fontFamily: "Cairo_700Bold",
  },
  subtext: {
    fontSize: 14,
    color: "gray",
    textAlign: "right",
    fontFamily: "Cairo_400Regular",
  },
  price: {
    fontSize: 14,
    color: "#E63946dd",
    textAlign: "right",
    fontFamily: "Cairo_400Regular",
  },
  delete: {
    fontSize: 14,
    textAlign: "right",
    fontFamily: "Cairo_400Regular",
    color: "#457B9D",
  },
  ConformModal: {
    backgroundColor: "white",
    alignItems: "center",
    padding: 20,
    borderRadius: 20,
  },
  deleteButton: {
    backgroundColor: "#E63946dd",
    padding: 10,
    borderRadius: 10,
    paddingHorizontal: 30,
    marginHorizontal: 10,
  },
  cancelbutton: {
    backgroundColor: "#1D3557",
    padding: 10,
    borderRadius: 10,
    paddingHorizontal: 30,
    marginHorizontal: 10,
  },
  deleteText: {
    color: "white",
    fontFamily: "Cairo_700Bold",
  },
  cancelText: {
    color: "white",
    fontFamily: "Cairo_700Bold",
  },
  confirmtext: {
    fontFamily: "Cairo_700Bold",
    marginVertical: 20,
  },

  detailsData: {
    textAlign: "right",
    fontFamily: "Cairo_400Regular",
  },
});

export default PaymentCard;
