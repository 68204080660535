import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet,TextInput,TouchableWithoutFeedback, Keyboard,ActivityIndicator  } from 'react-native';
import Apis from './Apis';
import Toast from 'react-native-toast-message';
import { ConfirmationModal } from './ConfirmationModal';

import { ErrorModal } from './ErrorModal';

function SendNotification({navigation}) {

    const [title,setTitle]=useState("")
    const [msgBody,setMsgBody]=useState("")
    const [ActivityIndicatorVisible,setActivityIndicatorVisible]=useState(false)
    const [errorModalVisible,setErrorModalVisible]=useState(false)
    const [errorModalMessage,setErrorModalMessage]=useState("")
    const [confirmationModalVisible,setConfirmationModalVisible]=useState(false)
    const [confirmationModalMessage,setConfirmationModalMessage]=useState("")
    const [onModalPress,setOnModalPress]=useState(()=>{})
  return (


    <View style={styles.container}>
    {
      errorModalVisible&& <ErrorModal msg={errorModalMessage} setVisible={setErrorModalVisible}/>
    }
   {
      confirmationModalVisible&& <ConfirmationModal onpress={onModalPress} msg={confirmationModalMessage} setVisible={setConfirmationModalVisible}/>
    }
     <View style={{marginTop:50,marginBottom:"auto"}}>
   
     <Text style={styles.header}>
    ارسال اشعار الى المستخدمين
    </Text>

    <TextInput
      placeholder="عنوان الاشعار"
      onChangeText={text => setTitle(text)}
    
  value={title}
  style={styles.input}
  
  />
   <TextInput
      placeholder="نص الاشعار"
      onChangeText={text => setMsgBody(text)}
      multiline
      numberOfLines={4}
  value={msgBody}
  style={styles.inputbody}
  
  />
  
  <TouchableOpacity
  style={styles.login}
  disabled={ActivityIndicatorVisible}

  onPress={async()=>{

    setOnModalPress(()=>async()=>{
      const response = await Apis.sendNotification(title,msgBody)
      if(response.status=="success"){
        Toast.show({
          type: 'success',
          text1:"تم ارسال الاشعار بنجاح"
        })    
  
  
      }    })
          setConfirmationModalMessage("هل أنت متأكد أنك تريد ارسال الاشعار؟")
          setConfirmationModalVisible(true)
         



   
 
     }}
  >
     {
      ActivityIndicatorVisible?<ActivityIndicator size="small" color="#fff" />:<Text style={styles.button}>
    ارسال
    </Text>
    }
  </TouchableOpacity>


     </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  settingContainer: {
    flexDirection: 'row-reverse',
    justifyContent:"space-between",
    alignItems: 'center',
    paddingVertical: 20,
  },
  settingText: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Cairo_400Regular",

  },
  titleText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: "Cairo_700Bold",

  },
  arrow:{
    width:15,
    height:15
  },
  header:{
    textAlign:"center",
    fontFamily: "Cairo_700Bold",
    fontSize:25,
    color:"#E63946dd",
    marginBottom:50
  },
  input:{
    textAlign:"right",
    fontFamily: "Cairo_400Regular",
    padding:10,
    width:"90%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:10,
    marginVertical:10,
    
  },
  inputbody:{
    textAlign:"right",
    fontFamily: "Cairo_400Regular",
    padding:10,
    width:"90%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:10,
    marginVertical:10,
    height:100
    
  },
  login:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
    backgroundColor:"#E63946dd"
  },
  signup:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
  },
  button:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"white"
  },
  signupbutton:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"#E63946dd"
  },
  Modal:{
    backgroundColor:"white",
    alignItems:"center",
    padding:20,
    borderRadius:20
  },
  ModalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20
  },
});

export default SendNotification;
