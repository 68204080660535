import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Button, Card, Text } from 'react-native-elements'; // Replace '@ui-kitten/components' with 'react-native-elements'
import { Divider } from 'react-native-paper';
import Apis from './Apis';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import { ConfirmationModal } from './ConfirmationModal';
import Modal from "react-native-modal";

export const ProductActions = ({ special, id, setVisible, navigation, notactivated }) => {
  const [visible1, setVisible1] = useState(true);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [onModalPress, setOnModalPress] = useState(() => {});

  async function deleteProduct() {
    const response = await Apis.DeleteProduct(id);
    if (response.status === 'success') {
      Toast.show({
        type: 'success',
        text1: 'تم الغاء تفعيل المنتج بنجاح',
      });
    }
    setVisible(false);
  }

  async function ReactivateProduct() {
    const response = await Apis.ReactivateProduct(id);
    if (response.status === 'success') {
      Toast.show({
        type: 'success',
        text1: 'تم تفعيل المنتج بنجاح',
      });
    }
    setVisible(false);
  }

  async function RequestSpecialProduct() {
    const response = await Apis.RequestSpecialProduct(id);
    if (response.status === 'success') {
      Toast.show({
        type: 'success',
        text1: 'تمت العملية بنجاح',
      });
    } else {
      Toast.show({
        type: 'error',
        text1: 'حدث خطأ, الرجاء المحاولة لاحقاً',
      });
    }
    setVisible(false);
  }

  async function stopSpecialProduct() {
    const response = await Apis.stopSpecialProduct(id);
    if (response.status === 'success') {
      Toast.show({
        type: 'success',
        text1: 'تم ايقاف منتج مميز بنجاح',
      });
    } else {
      Toast.show({
        type: 'error',
        text1: 'تم تقديم الطلب مسبقاً',
      });
    }
    setVisible(false);
  }

  return (
    <Modal isVisible={visible1}
    onBackdropPress={() => setVisible(false)}
    >
        <Card disabled={true}>
  
  <View
  >
    {
      confirmationModalVisible&& <ConfirmationModal onpress={onModalPress} msg={confirmationModalMessage} setVisible={setConfirmationModalVisible}/>
    }
<TouchableOpacity
  onPress={async()=>{

    navigation.push("editProduct",{
      id:id
    })
 setVisible(false)

  }}
  style={{marginHorizontal:10}}
  >
      <View
      style={{
          marginLeft:"auto",
          marginRight:"auto",
          borderRadius:10,
          marginTop:10,
         padding:10,
          justifyContent:"center",
          width:"100%"
      }}
      >
        <Text
style={{color:"#000",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
تعديل المنتج
</Text>
<Text
style={{color:"#000",fontFamily:"Cairo_400Regular",textAlign:"center",fontSize:12}}

>
* ملاحظة: تعديل المنتج سيؤدي الى الغاء تفعيل هذا المنتج واضافة منتج بالتعديلات الجديدة بدلاً منه
</Text>
          
      </View>

  </TouchableOpacity>
  <Divider/>
{
special?(<>
<TouchableOpacity
  onPress={async()=>{

    setOnModalPress(()=>()=>{
      stopSpecialProduct()
    })
    setConfirmationModalMessage("هل أنت متأكد أنك تريد ايقاف منتج مميز؟")
    setConfirmationModalVisible(true)
              }}
  style={{marginHorizontal:10}}

  >
      <View
      style={{
          marginLeft:"auto",
          marginRight:"auto",
          padding:10,
          borderRadius:10,
          marginTop:10,
          justifyContent:"center",
          width:"100%"
      }}
      >
        <Text
style={{color:"#000",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
إيقاف منتج مميز </Text>

<Text
style={{color:"#000",fontFamily:"Cairo_400Regular",textAlign:"center",fontSize:12}}

>
* المنتج المميز يظهر في الصفحة الرئيسية للتطبيق و له فرصة مشاهدة أكبر
</Text>

          
      </View>
  </TouchableOpacity>
  <Divider/>
</>):(<>
<TouchableOpacity
  onPress={async()=>{

    setOnModalPress(()=>()=>{
      RequestSpecialProduct()
    })
    setConfirmationModalMessage("هل أنت متأكد أنك تريد تفعيل منتج مميز؟")
    setConfirmationModalVisible(true)
              }}
  style={{marginHorizontal:10}}

  >
      <View
      style={{
          marginLeft:"auto",
          marginRight:"auto",
          padding:10,
          borderRadius:10,
          marginTop:10,
          justifyContent:"center",
          width:"100%"
      }}
      >
        <Text
style={{color:"#000",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
تفعيل منتج مميز  </Text>

<Text
style={{color:"#000",fontFamily:"Cairo_400Regular",textAlign:"center",fontSize:12}}

>
* المنتج المميز يظهر في الصفحة الرئيسية للتطبيق و له فرصة مشاهدة أكبر
</Text>


          
      </View>
  </TouchableOpacity>
  <Divider/>
</>)
}

  {
    notactivated&&(
      <>
         <TouchableOpacity
  onPress={async()=>{
    setOnModalPress(()=>()=>{
      ReactivateProduct()
    })
    setConfirmationModalMessage("هل أنت متأكد أنك تريد تفعيل المنتج؟")
    setConfirmationModalVisible(true)
  
  }}
  style={{marginHorizontal:10}}
  >
      <View
      style={{
          marginLeft:"auto",
          marginRight:"auto",
          borderRadius:10,
          marginTop:10,
         padding:10,
          justifyContent:"center",
          width:"100%"
      }}
      >
        <Text
style={{color:"#000",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
تفعيل المنتج
</Text>

          
      </View>

  </TouchableOpacity>
  <Divider/>

      </>
    )
  }

{
!notactivated&&(<>
 <TouchableOpacity
  onPress={async()=>{
    setOnModalPress(()=>()=>{
      deleteProduct()
    })
    
    setConfirmationModalMessage("هل أنت متأكد أنك تريد الغاء تفعيل المنتج؟")
    setConfirmationModalVisible(true)
 
        }}
  style={{marginHorizontal:10}}

  >
      <View
      style={{
          marginLeft:"auto",
          marginRight:"auto",
          backgroundColor:"#E63946",
          padding:10,
          borderRadius:10,
          marginTop:30,
          justifyContent:"center",width:"100%"
          
      }}
      >
        <Text
style={{color:"white",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
الغاء تفعيل المنتج
</Text>

          
      </View>
  </TouchableOpacity>
</>)
}

  </View>
</Card>
    </Modal>
  
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 192,
  },
  modalText: {
    fontFamily: 'Cairo_700Bold',
    marginVertical: 20,
    textAlign: 'center',
    fontSize: 20,
  },
});
