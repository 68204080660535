import React, { useState, useCallback, useEffect } from "react";
import {
  View,
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  RefreshControl,
  TouchableOpacity,
  TextInput,
  Image,
} from "react-native";
import { Text, SkeletonView } from "react-native-ui-lib";
import ProductsCardsSquare from "./ProductsCardsSquare";
import { Ionicons } from "@expo/vector-icons";
import Apis from "./Apis";
import { Feather } from "@expo/vector-icons";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import AWS from "aws-sdk/dist/aws-sdk-react-native";
import * as ImageManipulator from "expo-image-manipulator";
import { Buffer } from "buffer";
import Modal from "react-native-modal";
import Toast from "react-native-toast-message";
import { Divider } from "react-native-paper"
import { ConfirmationModal } from "./ConfirmationModal";
const { S3Client, PutObjectCommand } = require("@aws-sdk/client-s3");
const axios = require("axios");

const Ads = ({ navigation }) => {
  const [isAdsAvailable, setIsAdsAvailable] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [ads, setAds] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [activeModal, setActiveModal] = useState(0);

  const toggleModal = () => {
    if (activeModal !== 0) {
      setActiveModal(0);
    }
  };

  const getAds = async () => {
    const response = await Apis.getAds();

    if (response.status === "success") {
      setAds(response.data);
      setIsAdsAvailable(true);
    }
  };

  const LoadingView = ({ visible }) => {
    return (
      <Modal isVisible={visible} transparent={true}>
        <View
          style={{
            flex: 1,
            backgroundColor: "rgba(0,0,0,0.5)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator size="large" color="white" />
        </View>
      </Modal>
    );
  };

  useEffect(() => {
    getAds();
  }, []);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    setIsAdsAvailable(false);
    getAds();
    setRefreshing(false);
  }, []);

  function makeotp(length) {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleFirstImage = async () => {
    const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
    //if (status === "granted") 
    {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false,
        base64: true,
      });
      if (!result.cancelled) {
        setPageLoading(true);

        var id = makeotp(5);
      
       const compressedImage = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          [],
          { base64: true, format: "jpeg" }
        );
       // console.log(compressedImage)
        //uploadToS3(id, compressedImage.uri);
        await uploadToServer(compressedImage.uri);
      }
    }
  };
  const uploadToServer = async (file) => {
 
    try {
      const responseupload = await Apis.uploadToServer({
        base64:file
      })
  const response = await Apis.createAd(responseupload.location);
//console.log(response)
  if (response.status === "success") {
    setPageLoading(false);
    setRefreshing(true);
    setIsAdsAvailable(false);
    getAds();
    setRefreshing(false);
  }
    
    } catch (error) {
      console.error("Error uploading to server:", error);
    }
  };
  
 
  const uploadToS3 = async (base64) => {
 AWS.config.update({
          accessKeyId: "AKIAZI2LFBIE4NGVSN4K",
        secretAccessKey: "/ugnlEHuDLMJ/TJ1o2e11AkFHtMzav8pA8pUltbd",
        });
    const s3 = new AWS.S3();
 
    
    const base64Data = new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
  
    const type = base64.split(';')[0].split('/')[1];
    const file = {
      name: makeotp(10) + ".jpg",
      type: "image/jpeg",
    };
  
    const params = {
      Bucket: "bombasticstore",
      Key: "images/" + file.name,
      Body: base64Data,
      ACL: 'public-read',
      ContentEncoding: 'base64', // required
      ContentType: `image/${type}` // required. Notice the back ticks
    }
  
    let location = '';
    let key = '';
    try {
      const { Location, Key } = await s3.upload(params).promise();
      location = Location;
      key = Key;
         const response = await Apis.createAd(location);

      if (response.data.status === "success") {
        setPageLoading(false);
        setRefreshing(true);
        setIsAdsAvailable(false);
        getAds();
        setRefreshing(false);
      }
   
    } catch (error) {
    }
  

  
  
  










  };
  const component = (props) => {
    return (
      <>
        <View
          style={{
            marginTop: "auto",
            marginBottom: 30,
          }}
        >
          <TouchableOpacity
            style={{
              width: "100%",
              marginVertical: 10,
            }}
            onPress={async () => {
              if (props.item.activated) {
                const response = await Apis.deActivateAd(props.item.id);
                if (response.status === "success") {
                  Toast.show({
                    type: "success",
                    text1: response.msg,
                  });
                  toggleModal();
                  setRefreshing(true);
                  setIsAdsAvailable(false);
                  getAds();
                  setRefreshing(false);
                }
              } else {
                const response = await Apis.activateAd(props.item.id);
                if (response.status === "success") {
                  Toast.show({
                    type: "success",
                    text1: response.msg,
                  });
                  toggleModal();
                  setRefreshing(true);
                  setIsAdsAvailable(false);
                  getAds();
                  setRefreshing(false);
                }
              }
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontFamily: "Cairo_700Bold",
                fontSize: 18,
                color: props.item.activated ? "#E63946dd" : "#4daa57dd",
              }}
            >
              {props.item.activated ? " ايقاف التفعيل" : "إعادة التفعيل"}
            </Text>
          </TouchableOpacity>
          <Divider />
          <TouchableOpacity
            style={{
              width: "100%",
              marginVertical: 10,
            }}
            onPress={() =>
              setModalVisible(true)
            }
          >
            <Text
              style={{
                textAlign: "center",
                fontFamily: "Cairo_700Bold",
                fontSize: 18,
                color: "#E63946dd",
              }}
            >
              حذف
            </Text>
          </TouchableOpacity>
        </View>
        {
          isModalVisible&&(
               <ConfirmationModal msg={"هل أنت متأكد أنك تريد حذف الاعلان؟"} onpress={async()=>{
            const response = await Apis.deleteAd(props.item.id)
                if(response.status=="success"){
                  Toast.show({
                    type: 'success',
                    text1:response.msg
                  })  
                  //props.spSheet.hide()
                  toggleModal()
                  setRefreshing(true);
                  setIsAdsAvailable(false)
                  getAds();
                  setRefreshing(false)

                }
        }} setVisible={setModalVisible}/>
          )
        }
     
      </>
    );
  };

  return (
    <>
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        {/* Your main screen content here */}
        <LoadingView visible={pageLoading} />
      </View>
      <TouchableOpacity
        style={{
          position: "absolute",
          zIndex: 9999,
          elevation: 9999,
          bottom: 30,
          left: 20,
        }}
        onPress={() => {
          handleFirstImage();
        }}
      >
        <View
          style={{
            width: 50,
            height: 50,
            backgroundColor: "#E63946dd",
            borderRadius: 25,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Feather name="plus" color={"#ffffffdd"} size={40} />
        </View>
      </TouchableOpacity>

      <View style={styles.container}>
        <View style={styles.Innercontainer}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ paddingTop: 30 }}
            scrollEventThrottle={400}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={onRefresh}
                tintColor={"#E63946dd"}
                colors={["#E63946dd"]}
              />
            }
          >
            {isAdsAvailable && (
              ads.map((item) => (
                <TouchableOpacity
                  key={item.id}
                  onPress={() => {
                    setActiveModal(item.id);
                  }}
                >
                  <View style={{ overflow: "hidden" }}>
                    <Image source={{ uri: item.image }} style={styles.ad} />

                    <View
                      style={[
                        styles.tag,
                        {
                          backgroundColor: item.activated
                            ? "#4daa57dd"
                            : "#E63946dd",
                        },
                      ]}
                    >
                      <Text style={styles.tagText}>
                        {item.activated ? "فعال" : "متوقف"}
                      </Text>
                    </View>
                  </View>

                  <Modal
                    isVisible={activeModal == item.id}
                    onSwipeComplete={toggleModal}
                    swipeDirection={["up", "left", "right", "down"]}
                    onBackdropPress={toggleModal}
                    style={{ justifyContent: "flex-end", margin: 0 }}
                  >
                    <View
                      style={{
                        backgroundColor: "white",
                        padding: 16,
                      }}
                    >
                      {/* Add your modal content here */}
                      {component({ item, onClose: toggleModal })}
                    </View>
                  </Modal>
                </TouchableOpacity>
              ))
            )}

            {!isAdsAvailable && (
              <SkeletonView
                template={SkeletonView.templates.LIST_ITEM}
                times={10}
                circle={true}
              />
            )}
          </ScrollView>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
  },
  ad: {
    width: "100%",
    aspectRatio: 2 / 1,
    resizeMode: "stretch",
    marginBottom: 20,
    borderRadius: 20,
  },
  tag: {
    position: "absolute",
    top: 25,
    right: -40,
    width: 150,
    height: 30,
    transform: [{ rotate: "45deg" }],
    justifyContent: "center",
    alignItems: "center",
  },
  tagText: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    fontFamily: "Cairo_700Bold",
  },
  Innercontainer: {
    backgroundColor: "white",
    width: "90%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  searchInput: {
    width: "100%",
    backgroundColor: "#0000000d",
    marginLeft: "auto",
    marginRight: "auto",
    padding: 13,
    borderRadius: 10,
    fontFamily: "Cairo_400Regular",
    textAlign: "right",
  },
  searchInputText: {
    textAlign: "right",
    color: "#00000080",
    fontFamily: "Cairo_400Regular",
  },
  searchInputTextBlack: {
    textAlign: "right",
    color: "black",
    fontFamily: "Cairo_700Bold",
  },
  option: {
    textAlign: "right",
    paddingHorizontal: 20,
    paddingVertical: 15,
    fontFamily: "Cairo_700Bold",
    fontSize: 14,
  },
});

export default Ads;
