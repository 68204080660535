import React, { useState,useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet,TextInput,ActivityIndicator } from 'react-native';
import { Divider } from "react-native-paper"
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Dialog ,PanningProvider} from 'react-native-ui-lib';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import * as Notifications from 'expo-notifications';
import { ErrorModal } from './ErrorModal';
import Apis from './Apis';
function Landing({navigation}) {

    const [username,setUsername]=useState("")
    const [loginPassword,setLoginPassword]=useState("")
    const [ActivityIndicatorVisible,setActivityIndicatorVisible]=useState(false)
    const [errorModalVisible,setErrorModalVisible]=useState(false)
    const [errorModalMessage,setErrorModalMessage]=useState("")
    useEffect(() => {
  
      const checktoken=async()=>{
        const token=await AsyncStorage.getItem("token")
        if(token){
    
       
          navigation.reset({
            index: 0,
            routes: [{ name: 'Home' }],
          });
        }
        else
        {

        }
      }
     
      checktoken();
  
    }, [])
  return (
    <View style={styles.container}>
     <View style={{marginTop:"auto",marginBottom:"auto"}}>
   
     <Text style={styles.header}>
        أهلاً بك في متجر الوفيرا
    </Text>
    {
      errorModalVisible&& <ErrorModal msg={errorModalMessage} setVisible={setErrorModalVisible}/>
    }
   

    <TextInput
      label="اسم المستخدم"
      placeholder="اسم المستخدم"
      onChangeText={text => setUsername(text)}

  value={username}
  style={styles.input}

  />
    <TextInput
      placeholder="كلمة المرور"
      onChangeText={text => setLoginPassword(text)}
    secureTextEntry
  value={loginPassword}
  style={styles.input}
  
  />
  <TouchableOpacity
    onPress={()=>{
        navigation.replace("forgotpassword")
      }}
  >
    <Text style={{textAlign:"right",width:"90%", fontFamily: "Cairo_400Regular", color:"#E63946dd"}}>
    نسيت كلمة المرور؟
  </Text>
  </TouchableOpacity>
  
  <TouchableOpacity
  style={styles.login}
  disabled={ActivityIndicatorVisible}
  onPress={async()=>{
    setActivityIndicatorVisible(true)
    const response=await Apis.login(username,loginPassword)

    //console.log(response)
    if(response.status=="success"){
      await AsyncStorage.setItem("token",response.token)
      //registerForPushNotificationsAsync()
      navigation.reset({
        index: 0,
        routes: [{ name: 'Home' }],
      });
        

    }
    else{
      setActivityIndicatorVisible(false)
      setErrorModalMessage(response.msg)
      setErrorModalVisible(true)
    
    }
   
    



  }}
  >
    {
        ActivityIndicatorVisible?<ActivityIndicator size="small" color="#fff" />:<Text style={styles.button}>
        تسجيل الدخول
    </Text>
    }
    
  </TouchableOpacity>


     </View>

    </View>
  );
}
async function registerForPushNotificationsAsync() {
  let token;

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync({experienceId :"@wissamardah/shine-panel"})).data;
    //console.log(token);
  
    await Apis.updateNotificationToken(token)

  return token;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  settingContainer: {
    flexDirection: 'row-reverse',
    justifyContent:"space-between",
    alignItems: 'center',
    paddingVertical: 20,
  },
  settingText: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Cairo_400Regular",

  },
  titleText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: "Cairo_700Bold",

  },
  arrow:{
    width:15,
    height:15
  },
  header:{
    textAlign:"center",
    fontFamily: "Cairo_700Bold",
    fontSize:30,
    color:"#E63946dd",
    marginBottom:50
  },
  input:{
    textAlign:"right",
    fontFamily: "Cairo_400Regular",
    padding:10,
    width:"90%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
  },
  login:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
    backgroundColor:"#E63946dd"
  },
  signup:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
  },
  button:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"white"
  },
  signupbutton:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"#E63946dd"
  },
  Modal:{
    backgroundColor:"white",
    alignItems:"center",
    padding:20,
    borderRadius:20
  },
  ModalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20
  },
});

export default Landing;
