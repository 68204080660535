
import React,{useState,useCallback,useEffect} from "react"
import {View, ScrollView ,StyleSheet,RefreshControl,TouchableOpacity,TextInput  } from "react-native"
import { Text, SkeletonView,ActionSheet } from "react-native-ui-lib";
import ProductsCardsSquare from "./ProductsCardsSquare";
import { Ionicons } from "@expo/vector-icons";
import Apis from "./Apis";
import { Feather } from "@expo/vector-icons";
 const Products=({navigation})=>{
    const [isProductsAvailable,setIsProductsAvailable]=useState(true)
    const [refreshing, setRefreshing] = useState(false);
    const [isFilterShown, setIsFilterShown] = useState(false);
    const [searchQue,setSearchQue]=useState("")
    const [products,setProducts]=useState([])
    const [itemsCount,setItemsCount]=useState(0)
    const [orderBy,setOrderBy]=useState("id")
    const [orderType,setOrderType]=useState("desc")
    const [totalProducts,setTotalProducts]=useState(0)
    const [que,setque]=useState("")
    const [typingTimeout, setTypingTimeout] = useState(0);

    const handleTextInputChange = (text) => {
      setque(text);
      clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          setItemsCount(0)
          SearchStoreProducts(itemsCount,text)
        }, 500)
      );
    };
    const SearchStoreProducts=async(itemsCount,text)=>{
      const response=await Apis.SearchStoreProducts(text.trimEnd(),itemsCount);
 
 setTotalProducts(response.productCount)
 
       if(response.status=="success"){
        
         setItemsCount(response.data.length)
         var productsSub=[]
         response.data.forEach(s => {
          if(s.activated)
           productsSub.push(
             {
               id:s.id,
               image:{uri:s.mainimage},
               name:s.name,
               category:s.catname,
               price:s.storePrice,
               discount:s.discount,
               maincatname:s.maincatname,
               activated:s.activated,
               rejected:s.rejected,
               underreview:s.underreview,
               special:s.special,
               views:s.views,
               quantity:s.quantity
           },
           )
 
         });
         
         setProducts(productsSub)
         setIsProductsAvailable(true)
 
       }
     }
    const getProductsBySubCategory=async(itemsCount)=>{
     const response=await Apis.getStoreProducts(itemsCount);

setTotalProducts(response.productCount)

      if(response.status=="success"){
       
        setItemsCount(response.data.length)
        var productsSub=[]
        response.data.forEach(s => {
          if(s.activated)
          productsSub.push(
            {
              id:s.id,
               image:{uri:s.mainimage},
               name:s.name,
               category:s.catname,
               price:s.storePrice,
               discount:s.discount,
               maincatname:s.maincatname,
               activated:s.activated,
               rejected:s.rejected,
               underreview:s.underreview,
               special:s.special,
               views:s.views,
               quantity:s.quantity

          },
          )

        });
        setProducts(productsSub)
        setIsProductsAvailable(true)

      }
    }


    useEffect(() => {
      getProductsBySubCategory(0);

    }, [])
    const onRefresh = useCallback(() => {
        setRefreshing(true);
        setIsProductsAvailable(false)
        setItemsCount(0)
        getProductsBySubCategory(0);

        setRefreshing(false)
        
      }, []);


      const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
        const paddingToBottom = 150;
        return layoutMeasurement.height + contentOffset.y >=
          contentSize.height - paddingToBottom;
      };
         
     
    return(
        <>
        <TouchableOpacity
        style={{
            position:"absolute",
            zIndex:9999,
            elevation:9999,
            bottom:30,
            left:20
            }}

            onPress={()=>{
                navigation.navigate("addProduct")
            }}
        >
        <View
        style={{
            width:50,
            height:50,
            backgroundColor:"#E63946dd",
            borderRadius:25,
            alignItems:"center",
            justifyContent:"center"
        }}
        >
  <Feather name="plus" color={"#ffffffdd"} size={40} />

        </View>
        </TouchableOpacity>
   
         <View style={styles.container}>
      <View style={styles.Innercontainer}>
    
        <View 
        style={{
            marginTop:70
        }}
        >
                  <TextInput
          
          value={que}
          onChangeText={(text) => handleTextInputChange(text)}
        
        placeholder="ابحث حسب رقم او اسم المنتج" style={styles.searchInput}/>
      <View style={{ flexDirection: 'row', alignContent:"space-between",zIndex:9999 }}>
       
            <Text style={{flex:1,textAlign:"right",fontFamily: "Cairo_700Bold"}}>{products.length} نتيجة من أصل {totalProducts}</Text>
        </View>
        </View>
     
    
        <ScrollView
        showsVerticalScrollIndicator={false}
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
           //alert("close")
           handleTextInputChange(que);
          }
        }}
        scrollEventThrottle={400}
        refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} tintColor={"#E63946dd"}  colors={["#E63946dd"]}/>
          }
        >
          
        {
                isProductsAvailable?<ProductsCardsSquare navigation={navigation} products={products}/>
                :<SkeletonView
              template={SkeletonView.templates.LIST_ITEM}
              times={10}
              circle={true}
            
            />
            }
        </ScrollView>
        </View>
        </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
      backgroundColor: "white",
      width: "100%",
      height: "100%",
    },
    Innercontainer: {
      backgroundColor: "white",
      width: "90%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    searchInput: {
      width: "100%",
      backgroundColor: "#0000000d",
      marginLeft: "auto",
      marginRight: "auto",
      padding: 13,
      borderRadius: 10,
      fontFamily: "Cairo_400Regular",
      textAlign:"right"
    },
    searchInputText: {
      textAlign: "right",
      color: "#00000080",
      fontFamily: "Cairo_400Regular",
    },
    searchInputTextBlack: {
      textAlign: "right",
      color: "black",
      fontFamily: "Cairo_700Bold",
    },
    option:{
        textAlign:"right",
        paddingHorizontal:20,
        paddingVertical:15,
        fontFamily: "Cairo_700Bold",
        fontSize:14
    }
  });
  
export default Products