import AsyncStorage from "@react-native-async-storage/async-storage";
import Toast from 'react-native-toast-message'

const url = "https://aloeveraapi.bombasticstore.net/api/";
//const url = "http://10.0.0.12:4000/api/";



export default {
  signup: async (signUpStoreName,signUpOwnerName,signupAddress,signUpMobile,signUpCategory,mainImageS3,signupPassword) => {
    return fetch(url + "signupStore", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        signUpStoreName,signUpOwnerName,signupAddress,signUpMobile,signUpCategory,mainImageS3,signupPassword
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  checkotp: async (mobile, otp) => {
    return fetch(url + "checkotpAdmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile,
        otp,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  login: async (username, password) => {
    return fetch(url + "adminLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  forgotpassword: async (mobile) => {
    return fetch(url + "forgotpasswordAdmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  newpassword: async (password) => {
    return fetch(url + "newPasswordAdmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        password,
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendNotification: async (title,body) => {
    return fetch(url + "sendNotification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body,
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updateName: async (fullname) => {
    return fetch(url + "updateName", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        fullname,
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updateData: async (address,country,city,nearLocation) => {
    return fetch(url + "updateData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        address,country,city,nearLocation
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  createAd: async (image) => {
    return fetch(url + "createAd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        image
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  UpdatePassword: async (currentPassword,newPassword) => {
    return fetch(url + "updatePassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        currentPassword,newPassword
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  UpdatePasswordStore: async (currentPassword,newPassword) => {
    return fetch(url + "updatePasswordAdmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        currentPassword,newPassword
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getUserData: async () => {
    return fetch(url + "userData", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getAds: async () => {
    return fetch(url + "getAdsAdmin", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getCategories: async () => {
    return fetch(url + "categories", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getSubCategories: async (id) => {
    return fetch(url + "subCategories?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  activateAd: async (id) => {
    return fetch(url + "activateAd?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deActivateAd: async (id) => {
    return fetch(url + "deActivateAd?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteAd: async (id) => {
    return fetch(url + "deleteAd?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getProductsBySubCategory: async (id,itemsCount,orderBy,orderType) => {
    return fetch(url + "products?id="+id+"&itemsCount="+itemsCount+"&orderBy="+orderBy+"&orderType="+orderType, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getProductsByStore: async (id,itemsCount,orderBy,orderType) => {
    return fetch(url + "productsByStore?id="+id+"&itemsCount="+itemsCount+"&orderBy="+orderBy+"&orderType="+orderType, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  search: async (que,itemsCount,orderBy,orderType) => {
    return fetch(url + "search?que="+que+"&itemsCount="+itemsCount+"&orderBy="+orderBy+"&orderType="+orderType, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getSpecialProductsFull: async (itemsCount,orderBy,orderType) => {
    return fetch(url + "specialProductsFull?itemsCount="+itemsCount+"&orderBy="+orderBy+"&orderType="+orderType, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getSpecialStores: async () => {
    return fetch(url + "specialStores", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getStores: async (itemsCount,que) => {
    return fetch(url + "stores?itemsCount="+itemsCount+"&que="+que, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getRandomProducts: async () => {
    return fetch(url + "randomProducts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getSpecialProducts: async () => {
    return fetch(url + "specialProducts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getProduct: async (id) => {
    return fetch(url + "productDetails?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getStore: async (id) => {
    return fetch(url + "store?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getProductAttachments: async (id) => {
    return fetch(url + "productAttachments?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getWishList: async () => {
    return fetch(url + "wishlist", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  isInWishList: async (id) => {
    return fetch(url + "isInWishList?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
       
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setWishList: async (id,status) => {
    return fetch(url + "setWishList?id="+id+"&status="+(status?"1":"0"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"يجب تسجيل الدخول لتفعيل هذه الميزة"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  buyNow: async (cart,area,city,address,nearplace,name,mobile,saveAddress,savePersonalInfo) => {
    return fetch(url + "buyNow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        cart,area,city,address,nearplace,name,mobile,saveAddress,savePersonalInfo
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  createOrder: async (area,city,address,nearplace,name,mobile,saveAddress,savePersonalInfo,coupon) => {
    return fetch(url + "createOrder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        area,city,address,nearplace,name,mobile,saveAddress,savePersonalInfo,coupon
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  reviewOrder: async (id) => {
    return fetch(url + "reviewOrder?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  confirmOrder: async (id,type) => {
    return fetch(url + "confirmOrder?orderId="+id+"&type="+type, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addToCart: async (product,quantity,productDetails) => {
    return fetch(url + "addToCart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        product,quantity,productDetails
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"يجب تسجيل الدخول لتفعيل هذه الميزة"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getCart: async () => {
    return fetch(url + "cart", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addQuantity: async (id) => {
    return fetch(url + "addQuantity?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  minusQuantity: async (id) => {
    return fetch(url + "minusQuantity?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteFromCart: async (id) => {
    return fetch(url + "deleteFromCart?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  checkCoupon: async (coupon) => {
    return fetch(url + "checkCoupon?coupon="+coupon, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getCoupons: async () => {
    return fetch(url + "getCoupons", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteCoupon: async (id) => {
    return fetch(url + "deleteCoupon?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  addCoupon: async (name,value) => {
    return fetch(url + "addCoupon?name="+name+"&value="+value, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addCategory: async (name,image) => {
    return fetch(url + "addCategory?name="+name+"&image="+image, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addSubCategory: async (name,image,maincat) => {
    return fetch(url + "addSubCategory?name="+name+"&image="+image+"&maincat="+maincat, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getShipping: async () => {
    return fetch(url + "getShipping", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteShipping: async (id) => {
    return fetch(url + "deleteShipping?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addShipping: async (area,price) => {
    return fetch(url + "addShipping?area="+area+"&price="+price, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getMostViewedProducts: async () => {
    return fetch(url + "mostViewedProducts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPreviousOrders: async () => {
    return fetch(url + "previousOrdersAdmin", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  logout: async () => {
    return fetch(url + "logoutAdmin", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        await AsyncStorage.setItem("token","")

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updateNotificationToken: async (token) => {
   
    endPoint="updateNotificationTokenAdmin"
    return fetch(url + endPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        token
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
       
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  uploadProduct: async ({mainImage,images,name,category,price,discount,properties,quantity}) => {
    console.log(JSON.stringify({
      mainImage,images,name,category,price,discount,properties
          }))
    return fetch(url + "uploadProductAdmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        mainImage,images,name,category,price,discount,properties,quantity
            }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"يجب تسجيل الدخول لتفعيل هذه الميزة"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  editProduct: async ({id,mainImage,images,name,category,price,discount,properties,quantity}) => {
    console.log(JSON.stringify({
      mainImage,images,name,category,price,discount,properties
          }))
    return fetch(url + "editProductAdmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        id,mainImage,images,name,category,price,discount,properties,quantity
            }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"يجب تسجيل الدخول لتفعيل هذه الميزة"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getStoreOrders: async () => {
    return fetch(url + "adminOrders", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getStoreProducts: async (itemsCount) => {
    return fetch(url + "adminProducts?itemsCount="+itemsCount, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getProductForEdit: async (id) => {
    return fetch(url + "productForEditAdmin?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  DeleteProduct: async (id) => {
    return fetch(url + "deleteProductAdmin?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  ReactivateProduct: async (id) => {
    return fetch(url + "reactivateProductAdmin?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  RequestSpecialProduct: async (id) => {
    return fetch(url + "makeSpecialProduct?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  stopSpecialProduct: async (id) => {
    return fetch(url + "stopSpecialProductAdmin?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  countProducts: async () => {
    return fetch(url + "countProductsAdmin", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  
  updateCartStatusStore: async (id,status) => {
    return fetch(url + "updateCartStatusAdmin?id="+id+"&status="+status, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteCategory: async (id) => {
    return fetch(url + "deleteCategory?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteSubCategory: async (id) => {
    return fetch(url + "deleteSubCategory?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteSubCategory: async (id) => {
    return fetch(url + "deleteSubCategory?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  SearchStoreProducts: async (que,itemsCount) => {
    return fetch(url + "adminSearchProduct?que="+que+"&itemsCount="+itemsCount, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  searchStoreOrders: async (que) => {
    return fetch(url + "adminOrdersSearch?que="+que, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPayments: async (que) => {
    return fetch(url + "payments", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getStoreData: async (que) => {
    return fetch(url + "adminData", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updateStoreInfo: async (storeName,category,address,mainImageS3,userName) => {
    console.log(JSON.stringify({
      storeName,category,address,mainImageS3,userName
          }))
    return fetch(url + "updateStoreData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
      body: JSON.stringify({
        name:storeName,category:category,address:address,logo:mainImageS3,userName
            }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"يجب تسجيل الدخول لتفعيل هذه الميزة"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  specialStoreRequest: async () => {
    return fetch(url + "requestSpecialStore", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  stopSpecialStoreRequest: async () => {
    return fetch(url + "stopSpecialStore", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  uploadToServer: async (formData) => {
    return fetch(url + "uploadImage", {
      method: "POST",
      body: JSON.stringify({
     base64:formData.base64
            }),
      headers: {
        "Content-Type": "application/json",

        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getSmsBalance: async () => {
    return fetch(url + "getSmsBalance", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await AsyncStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await AsyncStorage.setItem("token","")
          Toast.show({
            type: 'error',
            text1:"انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          })
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
