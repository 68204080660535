import React, { useState,useEffect } from 'react';
import { ScrollView, StyleSheet,RefreshControl,TextInput, TouchableOpacity  } from 'react-native';
import { View ,Text,StatusBar} from 'react-native';

import Apis from './Apis';
import { SkeletonView } from "react-native-ui-lib";
import OrderCompleteCartItem from './OrderCompleteCartItem';
import { Popup } from 'react-native-popup-confirm-toast'
//import { Button, Layout, MenuItem, OverflowMenu } from '@ui-kitten/components';
import { Ionicons } from "@expo/vector-icons";

function Orders({navigation}) {
  

  const [que,setque]=useState("")

  const [ordersFixed,setOrdersFixed]=useState([])
  const [orders,setOrders]=useState([])
  const [refreshing, setRefreshing] = useState(true);
    const [page,setPage]=useState(1)
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [filterVisible, setFilterVisible] = React.useState(false);
    const handleTextInputChange = (text) => {
      setque(text);
      clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          setPage(1)
          searchStoreOrders(text)
        }, 500)
      );
    };
    const onRefresh = () => {
        setRefreshing(true);
        setPage(1)
        getOrders()

      };
      async function searchStoreOrders(que){
        const response = await Apis.searchStoreOrders(que);
        if(response.status=="success"){

            setOrders([...response.data])

            setRefreshing(false)

        }
    }

    async function getOrders(){
        const response = await Apis.getStoreOrders();
        if(response.status=="success"){
          //console.log(response.data)
            setOrders([...response.data])
            setOrdersFixed([...response.data])
            setRefreshing(false)

        }
    }


    useEffect(()=>{

        getOrders()


    },[])
    const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
      const paddingToBottom = 150;
      return layoutMeasurement.height + contentOffset.y >=
        contentSize.height - paddingToBottom;
    };
    const onItemSelect = (index) => {
      setSelectedIndex(index);
      setFilterVisible(false);
      setPage(1)
      if(index==1){
      

        setOrders([...ordersFixed])
      }
      else if(index==2){
        const filteredData = ordersFixed.filter(order => order.adminstatus === 0 );

        setOrders([...filteredData])
      }
      else if(index==3){
        const filteredData = ordersFixed.filter(order => order.adminstatus === 1 );

        setOrders([...filteredData])
      }
      else if(index==4){
        const filteredData = ordersFixed.filter(order => order.adminstatus === 2 );

        setOrders([...filteredData])
      }
      else if(index==5){
        const filteredData = ordersFixed.filter(order => order.adminstatus === 3 );

        setOrders([...filteredData])
      }
      else if(index==6){
        const filteredData = ordersFixed.filter(order => (order.adminstatus === 4));

        setOrders([...filteredData])
      }
      else if(index==7){
        const filteredData = ordersFixed.filter(order => order.adminstatus === 5);

        setOrders([...filteredData])
      }
      else if(index==8){
        const filteredData = ordersFixed.filter(order => order.adminstatus === 6);

        setOrders([...filteredData])
      }

    };
    const renderFilterButton = () => (
      <TouchableOpacity
      onPress={()=>{
        setFilterVisible(true)
      }}
      >
<View>
        <Ionicons name="filter" color={"black"} size={25} />

</View>
      </TouchableOpacity>

     
    );
  
  return (
    <>
     {/*<TouchableOpacity
        style={{
            position:"absolute",
            zIndex:9999,
            elevation:9999,
            bottom:30,
            left:20
            }}

            onPress={async()=>{
              
              await AsyncStorage.setItem("token","")

              //alert(JSON.stringify(response))
               navigation.reset({
                      index: 0,
                      routes: [{ name: 'landing' }],
                    }); 
            }}
        >
        <View
        style={{
            width:50,
            height:50,
            backgroundColor:"#E63946dd",
            borderRadius:25,
            alignItems:"center",
            justifyContent:"center"
        }}
        >
  <MaterialIcons name="logout" color={"#ffffffdd"} size={30} />

        </View>
      </TouchableOpacity>*/}


    <View style={{width:"100%",height:"100%",backgroundColor:"#fff"}}>

<View style={{width:"90%",height:"100%",marginLeft:"auto",marginRight:"auto",paddingTop:70}}>
<TextInput
          
          value={que}
          onChangeText={(text) => handleTextInputChange(text)}
        
        placeholder="ابحث حسب رقم الطلبية او اسم او رقم المنتج" style={styles.searchInput}/>
  
  <View
  style={{flexDirection:"row-reverse"}}
  >
 <Text style={{textAlign:"right",fontFamily:"Cairo_700Bold",fontSize:16,color:"#000000cc"}}>

عدد الطلبيات : {orders.length}  
  </Text>
{
  /**
   * 
   *   <Layout style={{marginLeft:"auto"}}>
      <OverflowMenu
      style={{maxHeight:999}}
        anchor={renderFilterButton}
        visible={filterVisible}
        selectedIndex={selectedIndex}
        onSelect={onItemSelect}
        onBackdropPress={() => setFilterVisible(false)}>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الكل</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات الجديدة</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات الجاهزة للشحن</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات المشحونة</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الكميات المنتهية</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات الملغية</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات المرتجعة</Text>)}/>
        <MenuItem title={()=>(<Text style={{fontFamily: "Cairo_700Bold",width:"100%",textAlign:"right"}}>الطلبات المسلمة</Text>)}/>
  
      </OverflowMenu>
    </Layout>
   */
}

  </View>
 

<ScrollView
showsVerticalScrollIndicator={false}

refreshControl={
    <RefreshControl refreshing={refreshing} onRefresh={onRefresh}  tintColor="#E63946dd" />
  }
  onScroll={({nativeEvent}) => {
    if (isCloseToBottom(nativeEvent)) {
     //alert("close")
    
     setPage(page+1)
    }
  }}
>
  {
    refreshing?<SkeletonView
    template={SkeletonView.templates.LIST_ITEM}
    times={10}
    circle={true}
  
  />:<View style={{marginBottom:70}}>
    
  {
          orders.map(function(item, index) {
            if(index<page*10){
              return <OrderCompleteCartItem navigation={navigation} product={item} onchange={(type,msg)=>{
                getOrders()

                Popup.show({
                  type: type,
                  title: type=="success"?'تم':"خطأ",
                  textBody: msg,
                  buttonText: 'اغلاق',
                  titleTextStyle:{fontFamily:"Cairo_700Bold"},
                  descTextStyle:{fontFamily:"Cairo_400Regular"},
                  okButtonTextStyle:{fontFamily:"Cairo_700Bold"},
                  okButtonStyle:{backgroundColor: '#E63946dd'},
                  callback: () => Popup.hide(),
                  

                                })
              }}/>;
            
            }
            
        
          }
            )
        }
  

     
  </View>
  
  }


</ScrollView>

</View>
    </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  settingContainer: {
    flexDirection: 'row-reverse',
    justifyContent:"space-between",
    alignItems: 'center',
    paddingVertical: 20,
  },
  settingText: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Cairo_400Regular",

  },
  titleText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: "Cairo_700Bold",

  },
  arrow:{
    width:15,
    height:15
  },
  header:{
    textAlign:"center",
    fontFamily: "Cairo_700Bold",
    fontSize:20,
    color:"#E63946dd",
    marginBottom:50
  },
  input:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    padding:10,
    width:"50%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
  },
  login:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
    backgroundColor:"#E63946dd"
  },
  signup:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
  },
  button:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"white"
  },
  signupbutton:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"#E63946dd"
  },
  Modal:{
    backgroundColor:"white",
    alignItems:"center",
    padding:20,
    borderRadius:16
  },
  ModalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20
  },
  searchInput: {
    width: "100%",
    backgroundColor: "#0000000d",
    marginLeft: "auto",
    marginRight: "auto",
    padding: 13,
    borderRadius: 10,
    fontFamily: "Cairo_400Regular",
    textAlign:"right"
  },
});

export default Orders;
