import React, { useState,useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet,TextInput,TouchableWithoutFeedback, Keyboard,ActivityIndicator } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ErrorModal } from './ErrorModal';
import Apis from './Apis';
function OTP({navigation,route}) {
    const {mobile,target}=route.params;
    const [otpInput,setOtpInput]=useState("")
    const [ActivityIndicatorVisible,setActivityIndicatorVisible]=useState(false)
    const [errorModalVisible,setErrorModalVisible]=useState(false)
    const [errorModalMessage,setErrorModalMessage]=useState("")
    useEffect(() => {
    
     // console.log(route)
    }, [])
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
    <View style={styles.container}>
    {
      errorModalVisible&& <ErrorModal msg={errorModalMessage} setVisible={setErrorModalVisible}/>
    }
   
     <View style={{marginTop:"auto",marginBottom:"auto"}}>
   
     <Text style={[styles.header,{marginBottom:0}]}>
     الرجاء ادخال الرمز الذي تم ارساله الى 
    </Text>
    <Text style={styles.header}>
     {mobile}
    </Text>

    <TextInput
      placeholder="الرمز"
      keyboardType='numeric'
      onChangeText={text => setOtpInput(text)}
      maxLength={4}
  value={otpInput}
  style={styles.input}

  />
  <TouchableOpacity
  style={styles.login}
  disabled={ActivityIndicatorVisible}
  onPress={async()=>{
    setActivityIndicatorVisible(true)
    const response=await Apis.checkotp(mobile,otpInput)

    if(response.status=="success"){
      await AsyncStorage.setItem("token",response.token)

     // console.log(response)
      if(target=="newaccount"){
        navigation.reset({
          index: 0,
          routes: [{ name: 'Home' }],
        }); 
      }
      else if(target=="forgotpassword"){
        navigation.replace("newpassword")
      }

    

    }
    else{
      setActivityIndicatorVisible(false)
      setErrorModalMessage("رمز غير صحيح")
      setErrorModalVisible(true)
    }
   
  }}
  >
    {
      ActivityIndicatorVisible?<ActivityIndicator size="small" color="#fff" />:<Text style={styles.button}>
      التالي
    </Text>
    }
    
  </TouchableOpacity>
  <TouchableOpacity
  style={styles.signup}
  onPress={()=>{
    navigation.replace("landing")
  }}
  >
    <Text style={styles.signupbutton}>
      العودة
    </Text>
  </TouchableOpacity>

     </View>

    </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#fff',
  },
  settingContainer: {
    flexDirection: 'row-reverse',
    justifyContent:"space-between",
    alignItems: 'center',
    paddingVertical: 20,
  },
  settingText: {
    fontSize: 16,
    marginLeft: 10,
    fontFamily: "Cairo_400Regular",

  },
  titleText: {
    fontSize: 18,
    marginLeft: 10,
    fontFamily: "Cairo_700Bold",

  },
  arrow:{
    width:15,
    height:15
  },
  header:{
    textAlign:"center",
    fontFamily: "Cairo_700Bold",
    fontSize:20,
    color:"#E63946dd",
    marginBottom:50
  },
  input:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    padding:10,
    width:"50%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
  },
  login:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
    backgroundColor:"#E63946dd"
  },
  signup:{
    padding:10,
    width:"80%",
    marginLeft:"auto",
    marginRight:"auto",
    borderWidth:0.5,
    borderRadius:50,
    marginVertical:10,
    borderColor:"#E63946dd",
  },
  button:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"white"
  },
  signupbutton:{
    textAlign:"center",
    fontFamily: "Cairo_400Regular",
    color:"#E63946dd"
  },
  Modal:{
    backgroundColor:"white",
    alignItems:"center",
    padding:20,
    borderRadius:20
  },
  ModalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20
  },
});

export default OTP;
