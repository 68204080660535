
import React,{useState,useEffect} from "react"
import {Text, View ,StyleSheet,ActivityIndicator,TouchableOpacity,Image } from "react-native"
import { TextInput } from 'react-native-paper';
import { ActionSheet,Checkbox } from "react-native-ui-lib"; 
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Apis from "./Apis";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Toast from 'react-native-toast-message'
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import AWS from "aws-sdk/dist/aws-sdk-react-native";
import * as ImageManipulator from 'expo-image-manipulator';
import { Buffer } from "buffer"; // import buffer

 const StoreInfo=({navigation})=>{

  const [userName, setuserName] = useState("")
  const [mobile, setMobile] = useState("")
  const [logo, setLogo] = useState("")
  const [storeName, setStoreName] = useState("")
  const [category, setCategory] = useState("")
  const [address, setAddress] = useState("")
    const [loading, setLoading] = useState(false)
  
   const [mainImageS3, setMainImageS3] = useState(null);
   const [firstImage, setFirstImage] = useState(null);
   const [getItem, setGetItem] = useState(false);

   function makeotp(length) {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleFirstImage = async () => {

    const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
    if (status === "granted") {
      const result = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false,
        aspect: [3, 4],
        base64: true,
      });
      if (!result.canceled) {
        var id = makeotp(5);
        const compressedImage = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          [{ resize:{
          
            width:500
          } }],
          {base64:true, format: 'jpeg' }
        );
        setFirstImage({ id: id, uri: compressedImage.uri });
        uploadToS3(id, compressedImage.base64);
      }
    }
  };
  const uploadToS3 = (id, base64) => {
    setGetItem(false)

    AWS.config.update({
      accessKeyId: "AKIA3OW6HHJV4QMX3ONA",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3();
    const file = {
      name: makeotp(10) + ".jpg",
      type: "image/jpeg",
    };

    const params = {
      Bucket: "shineapp",
      Key: "images/" + file.name,
      Body: new Buffer(base64, "base64"),
      ContentType: file.type,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
       
       // console.log(err, err.stack);
      } else {
     //   console.log(data);
        setMainImageS3({ id, uri: data.Location });
        setGetItem(true)
      }
    })
  };

    async function updateStoreInfo(){
      setLoading(true)
      if(storeName&&category&&address&&mainImageS3&&userName){
        const response=await Apis.updateStoreInfo(storeName,category,address,mainImageS3.uri,userName);
        //alert(JSON.stringify(response))
        setLoading(false)
        if(response.status=="success"){
          Toast.show({
            type: 'success',
            text1:"تم تعديل البيانات بنجاح"
          })
        }
        else{
          Toast.show({
            type: 'error',
            text1:response.msg
          })
        }
      }
      else{
        setLoading(false)
        Toast.show({
          type: 'error',
          text1:"جميع الحقول اجبارية"
        })
      }
  
    }
    useEffect(() => {
      const unsubscribe = navigation.addListener('focus', async() => {
        // The screen is focused
        // Call any action
   
        
          const userData=await Apis.getStoreData()
         // alert(JSON.stringify(userData))
        // console.log(userData.data)
         const {userName,mobile,logo,storeName,category,address}=userData.data[0]
         setuserName(userName)
         setLogo(logo)
         setStoreName(storeName)
         setMobile(mobile)
         setCategory(category)
         setAddress(address)
         const imageid=makeotp(10)
         setFirstImage({id:imageid,uri:logo})
        setMainImageS3({id:imageid,uri:logo})
        setGetItem(true)
      });
  
      // Return the function to unsubscribe from the event so it gets removed on unmount
      return unsubscribe;
    }, [navigation]);
  
 
  
    return(
        <>
         <View style={{backgroundColor:"white",paddingBottom:20,paddingTop:10,borderTopWidth:0.5,borderColor:"#00000044",position:"absolute",bottom:0,zIndex:10,width:"100%",paddingHorizontal:15}}>
        
      
      
        <TouchableOpacity style={{width:"100%",height:"100%",justifyContent:"center"}}
        disabled={loading}
        onPress={()=>{
            updateStoreInfo();
        }}
        >

        <View style={styles.buynowContainer}>

{
  loading?
  <ActivityIndicator size="small" color="#fff" />
  :    <Text style={styles.checkcouponbutton}>

  حفظ    
        </Text>
}
                
</View>
</TouchableOpacity>


          

        </View>  
      <View style={styles.container}>
     
      <KeyboardAwareScrollView
            keyboardShouldPersistTaps='always'
            enableOnAndroid={true}
        >
            <View style={styles.Innercontainer}>

            <TouchableOpacity
                onPress={()=>{
                  handleFirstImage()

                }}
                >
                  <View
                    style={{
                      width: 100,
                      aspectRatio: 1 / 1,
                      marginLeft:"auto",
                      marginRight:"auto",
                      alignItems: "center",
                      borderWidth: firstImage?0:0.5,
                      borderRadius: 50,
                      marginHorizontal: 10,
                      shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 4,
},
shadowOpacity: 0.30,
shadowRadius: 4.65,
                    }}
                  >
                    {
                      firstImage?
                      <> 
                       <Image
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      borderRadius: 50,

                    }}
                    source={{uri:firstImage.uri}}
                  />
                    {
                !getItem&&(
                   <View 
              style={{position:"absolute",alignContent:"center",alignItems:"center",justifyContent:"center",width:"100%",height:"100%",backgroundColor:"#ffffff99"}}
              >
              <ActivityIndicator size="large" color="#E63946dd"/>
              
              </View>
                )
              }
                      </>
                  
                  
                      :
                      <Image
                        style={{
                          width: 30,
                          height: 30,
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        source={require("./assets/plus.png")}
                      />
                    }
                  </View>
                </TouchableOpacity>


                <TextInput
      label="اسم المتجر"
      placeholder="اسم المتجر"
      onChangeText={text => setStoreName(text)}

  value={storeName}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right"}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  />
              <TextInput
      label="اسم صاحب المتجر"
      placeholder="اسم صاحب المتجر"
      onChangeText={text => setuserName(text)}

  value={userName}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right"}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  />
    <TextInput
      label="تصنيف المتجر"
      placeholder="تصنيف المتجر"
      onChangeText={text => setCategory(text)}

  value={category}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right"}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  />
    <TextInput
      label="عنوان المتجر"
      placeholder="عنوان المتجر"
      onChangeText={text => setAddress(text)}

  value={address}
  mode={"outlined"}
  outlineColor={"#00000088"}
  activeOutlineColor={"#00000088"}
  style={{backgroundColor:"white",direction:"rtl",textAlign:"right"}}
  contentStyle={{fontFamily: "Cairo_400Regular"}}
  />
    
    </View>
    </KeyboardAwareScrollView>

</View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
      backgroundColor: "white",
      width: "100%",
      height: "100%",
    },
    Innercontainer: {
      backgroundColor: "white",
      width: "90%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    searchInput: {
      width: "100%",
      backgroundColor: "#0000000d",
      marginLeft: "auto",
      marginRight: "auto",
      padding: 13,
      borderRadius: 10,
    },
    searchInputText: {
      textAlign: "right",
      color: "#00000080",
      fontFamily: "Cairo_400Regular",
    },
    sectionTitle:{
        fontFamily: "Cairo_700Bold",
        fontSize:18,
        marginBottom:20
      },
      option:{
        textAlign:"right",
        paddingHorizontal:20,
        paddingVertical:15,
        fontFamily: "Cairo_700Bold",
        fontSize:14
    },
    buynowContainer:{
        backgroundColor:"#E63946dd",
        height:60,
        borderRadius:10,
        justifyContent:"center",
        alignItems:"center"
      },
      checkoutbuttoncontainer:{
        width:"100%",
        backgroundColor:"#E6394633",
        height:60,
        borderRadius:10,
        justifyContent:"center",
        alignItems:"center",
        marginHorizontal:10
      },
      checkcouponbutton:{
        fontFamily: "Cairo_700Bold",
        color:"white",
       textAlign:"center"
        },
  });
  
export default StoreInfo