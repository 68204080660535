import React from 'react';
import { StyleSheet, View ,Image,TouchableOpacity} from 'react-native';
//import { Button, Card, Modal, Text } from '@ui-kitten/components';
import { Divider,Button, Card, Text  } from "react-native-paper"
import Modal from "react-native-modal";

export const AddImageModal = ({onpressCamera,onpressStudio,setVisible}) => {

  const [visible1, setVisible1] = React.useState(true);

  return (
    <Modal
    visible={visible1}
    backdropStyle={styles.backdrop}
    onBackdropPress={() => setVisible(false)}>
    <Card disabled={true}>
  
      <Text style={styles.modalText}>كيف تريد إضافة الصورة؟</Text>
      <View
      >
              <Divider/>

  <TouchableOpacity
      onPress={async()=>{

     onpressCamera()
     setVisible(false)

      }}
      style={{marginHorizontal:10}}
      >
          <View
          style={{
              marginLeft:"auto",
              marginRight:"auto",
              borderRadius:10,
              marginTop:10,
             padding:10,
              justifyContent:"center",
              width:"100%"
          }}
          >
            <Text
  style={{color:"#000",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
  من الكاميرا
  </Text>
  
              
          </View>
      </TouchableOpacity>
      <Divider/>

      <TouchableOpacity
      onPress={async()=>{

        onpressStudio()
        setVisible(false)
            }}
      style={{marginHorizontal:10}}

      >
          <View
          style={{
              marginLeft:"auto",
              marginRight:"auto",
              padding:10,
              borderRadius:10,
              marginTop:10,
              justifyContent:"center",
              width:"100%"
          }}
          >
            <Text
  style={{color:"#000",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
  من الالبوم
  </Text>
  
              
          </View>
      </TouchableOpacity>
      <Divider/>

      <TouchableOpacity
      onPress={async()=>{

        setVisible(false)
            }}
      style={{marginHorizontal:10}}

      >
          <View
          style={{
              marginLeft:"auto",
              marginRight:"auto",
              padding:10,
              borderRadius:10,
              marginTop:30,
              justifyContent:"center",width:"100%"
              
          }}
          >
            <Text
  style={{color:"#E63946",fontFamily:"Cairo_700Bold",textAlign:"center",fontSize:16}}

>
 الغاء
  </Text>
  
              
          </View>
      </TouchableOpacity>

      </View>
    </Card>
  </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 192,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalText:{
    fontFamily: "Cairo_700Bold",
    marginVertical:20,
    textAlign:"center",
    fontSize:20
  }
});